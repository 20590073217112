import React, { useState } from 'react';
//import useLeavePageConfirmation from 'src/hooks/useLeavePageConfirmation';
import useFetch from 'src/hooks/useFetch';
// import Button from 'src/ui/Button';
import Container from 'src/ui/Container';
import LocalNav from 'src/ui/LocalNav';
import { config } from 'src/constants/endpoints';
import SuccessScreen from 'src/ui/SuccessScreen';
// import { ArrowClockwise } from 'react-bootstrap-icons';
import CourseForm from 'src/components/courses/CourseForm';

const CourseCreatePage = () => {
    // useLeavePageConfirmation(); // TODO: comment in
    const { data, errorMessage, isLoading, isSuccess, fetchData } = useFetch(config.url.COURSES);

    const [course, setCourse] = useState({});

    const [courseImage, setCourseImage] = useState('');

    const submitHandler = async (event) => {
        event.preventDefault();

        fetchData(
            {
                method: 'POST',
                body: JSON.stringify(course),
            },
            (data) => console.log(data)
        );

        //TODO: api call for courseImage;
    };

    return (
        <>
            {isSuccess ? (
                <SuccessScreen
                    title="New Course is created!"
                    message="Add scenarios and members to the course from the following link"
                    url={`/courses/${data.id}/edit`}
                    label="Add members and/or scenarios"
                />
            ) : (
                <>
                    <Container>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <LocalNav
                                label="Back to Course Lists"
                                to={'/courses'}
                            />

                            {/* <Button
                                //onClick={() => setIsModalOpen(true)}
                                variant="contained"
                                color="primaryLight"
                                //disabled={generateIsLoading || isLoading}
                            >
                                <ArrowClockwise />
                                Import from Blackboard
                            </Button> */}
                        </div>
                        <CourseForm
                            isLoading={isLoading}
                            errorMessage={errorMessage}
                            submitHandler={submitHandler}
                            courseImage={courseImage}
                            setCourseImage={setCourseImage}
                            course={course}
                            setCourse={setCourse}
                        />
                    </Container>
                </>
            )}
        </>
    );
};

export default CourseCreatePage;
