import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Container from 'src/ui/Container';
import { useNavigate } from 'react-router-dom';
import Button from 'src/ui/Button';
import Title from 'src/ui/Title';
import styled, { useTheme } from 'styled-components';
import Logo from 'src/assets/img/logo-vertical.png';

const StyledContainer = styled(Container)`
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.m};
`;

const SignOutPage = () => {
    const theme = useTheme();
    const { loginWithRedirect, isAuthenticated } = useAuth0();
    const navigate = useNavigate();

    const signInHandler = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: '/', // Define where to go after "/callback" page
            },
        });
    };
    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    if (!isAuthenticated) {
        return (
            <StyledContainer>
                <StyledContentWrapper>
                    <img
                        style={{ width: '150px', marginBottom: '40px' }}
                        src={Logo}
                        alt="logo"
                    />

                    <Title
                        component="h3"
                        color={theme.colors.darkGray}
                        style={{ marginBottom: 0 }}
                    >
                        You are logged out!
                    </Title>
                    <div>To use the application, please login.</div>
                    <Button
                        variant="contained"
                        color="primaryLight"
                        onClick={signInHandler}
                    >
                        Login
                    </Button>
                </StyledContentWrapper>
            </StyledContainer>
        );
    }
};

export default SignOutPage;
