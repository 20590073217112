import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import {
    Display,
    //Book,
    Pencil,
} from 'react-bootstrap-icons';
import { config } from 'src/constants/endpoints';
import useFetch from 'src/hooks/useFetch';
import { utcToEstNoTime } from 'src/utils/formatDate';
import ScenarioAnalytics from 'src/components/scenarios/ScenarioAnalytics';
import Button from 'src/ui/Button';
import Container from 'src/ui/Container';
import LocalNav from 'src/ui/LocalNav';
import Title from 'src/ui/Title';
import placeholderImage from 'src/assets/img/placeholderImage.jpg';
import SpinnerScreen from 'src/ui/SpinnerScreen';
import IconLabelLink from 'src/ui/IconLabelLink';
import NoResultScreen from 'src/ui/NoResultScreen';

const StyledTitleRow = styled.div`
    display: flex;
    justify-content: space-between;
`;
const StyledScenarioTitle = styled(Title)`
    max-width: ${({ theme }) => theme.breakPoints.s};
`;

const StyledButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.s};
`;
const StyedTopRow = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.l};
    margin-bottom: ${({ theme }) => theme.spacing.l};
`;

const StyledImageWrapper = styled.div`
    width: ${({ theme }) => theme.imageSize.l.width};
    height: ${({ theme }) => theme.imageSize.l.height};
`;
const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.m};
    object-fit: cover;
`;

// const StyledIconWrapper = styled.div`
//     border-radius: ${({ theme }) => theme.borderRadius.m};
//     border: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100%;
// `;
const StyledDescription = styled.p`
    flex: 1;
    max-width: ${({ theme }) => theme.breakPoints.s};
    font-size: ${({ theme }) => theme.fontSize.l};
`;
const StyledGridWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing.l};
    margin-bottom: ${({ theme }) => theme.spacing.l};
    & > div {
        width: ${({ theme }) => `calc(50% - ${theme.spacing.l})`};
    }
`;

const StyledScenarioAnalytics = styled.div`
    padding-top: ${({ theme }) => theme.spacing.m};
    border-top: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
`;

const StyledTitle = styled(Title)`
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing.l};
`;

const StyledBottomRow = styled.div`
    text-align: right;
    font-size: ${({ theme }) => theme.fontSize.s};
`;

const ScenarioDetailPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { courseId, scenarioId } = useParams();

    const { data, errorMessage, isLoading, isSuccess, fetchData } = useFetch(`${config.url.SCENARIOS}${scenarioId}/`);

    const [scenario, setScenario] = useState(null);

    useEffect(() => {
        fetchData({}, null, (error) => {
            // Redirecting when scenario id is invalid
            if (error.message === '404') {
                navigate('/not-found');
            }
        });
        // Don't want to add navigate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData]);

    useEffect(() => {
        if (isSuccess) {
            setScenario(data);
        }
    }, [isSuccess, data]);

    if (isLoading || (!scenario && !errorMessage)) {
        return <SpinnerScreen />;
    }

    if (errorMessage) {
        return <div>{errorMessage}</div>;
    }

    return (
        <>
            <LocalNav
                to={`/courses/${courseId}`} // TODO
                label="Back to Course Detail"
            />
            <Container>
                <StyledTitleRow>
                    <StyledScenarioTitle
                        component="h1"
                        color={theme.colors.primary}
                    >
                        {scenario.title}
                    </StyledScenarioTitle>
                    <StyledButtonWrapper>
                        <IconLabelLink to={`/scenarios/${scenario.id}/edit`}>
                            <Pencil />
                            Edit
                        </IconLabelLink>

                        <Button
                            variant="contained"
                            color="primaryLight"
                            to={`/scenarios/${scenario.id}/attempt`}
                        >
                            <Display />
                            Preview
                        </Button>
                    </StyledButtonWrapper>
                </StyledTitleRow>
                <StyedTopRow>
                    <StyledImageWrapper>
                        {/* TODO: add image
                            {scenario.image ? ( */}
                        <StyledImage
                            src={placeholderImage}
                            alt=""
                        />
                        {/*} ) : ( */}
                        {/* <StyledIconWrapper>
                            <Book
                                size="50"
                                color={theme.colors.gray}
                            />
                        </StyledIconWrapper> */}
                        {/* )} */}
                    </StyledImageWrapper>
                    <StyledDescription>{scenario.description}</StyledDescription>
                </StyedTopRow>
                <StyledGridWrapper>
                    {/* TODO: temp style */}
                    <div>
                        <Title
                            component={'h4'}
                            color={theme.colors.primary}
                        >
                            Patient Config
                        </Title>
                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '300px' }}>
                            <div>Patient Name</div>
                            <div>{scenario.patient_name}</div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: '300px' }}>
                            <div>Patient Date of Birth</div>
                            <div>{scenario.patient_dob}</div>
                        </div>
                    </div>
                    <div>
                        <Title
                            component={'h4'}
                            color={theme.colors.primary}
                        >
                            Patient Condition
                        </Title>
                        <p>{scenario.patient_condition}</p>
                    </div>
                    <div>
                        <Title
                            component={'h4'}
                            color={theme.colors.primary}
                        >
                            Patient HPI
                        </Title>
                        <p>{scenario.patient_hpi}</p>
                    </div>
                    <div>
                        <Title
                            component={'h4'}
                            color={theme.colors.primary}
                        >
                            Patient Allergies
                        </Title>
                        <p>{scenario.patient_allergies}</p>
                    </div>
                    <div>
                        <Title
                            component={'h4'}
                            color={theme.colors.primary}
                        >
                            Patient Symptoms
                        </Title>
                        <p>{scenario.patient_symptoms}</p>
                    </div>
                </StyledGridWrapper>
                <StyledScenarioAnalytics>
                    <StyledTitle
                        component={'h3'}
                        color={theme.colors.primary}
                    >
                        Scenario Analytics for Assigned Courses
                    </StyledTitle>
                    {scenario.courses.length > 0 ? (
                        <ScenarioAnalytics courses={scenario.courses} />
                    ) : (
                        <NoResultScreen
                            largeText={'This scenario is not assigned to any course.'}
                            text={`Please assign this course to a course from the course edit page.`}
                        />
                    )}
                </StyledScenarioAnalytics>

                <StyledBottomRow>Created at: {utcToEstNoTime(scenario.created_at)}</StyledBottomRow>
                {/* <div>Updated at: UPDated here!!</div> */}
            </Container>
        </>
    );
};

export default ScenarioDetailPage;
