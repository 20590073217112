import React from 'react';
import FormInputs from 'src/ui/form-ui/FormInputs';
import NoteButtons from 'src/components/attempts/NoteButtons';

import styled from 'styled-components';
import { PATIENT_NOTE } from 'src/constants/formElements/attempts';

const StyledNote = styled.form`
    display: flex;
    flex-direction: column;
`;
const StyledNoteInputs = styled(FormInputs)`
    padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.s} 0;
`;
const StyledInstructorsFooter = styled.div`
    background: ${({ theme }) => theme.colors.lightGray};
    border-radius: ${({ theme }) => theme.borderRadius.s};
    padding: ${({ theme }) => theme.spacing.s} ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.s} 0;
    color: ${({ theme }) => theme.colors.darkGray};
    text-align: center;
`;

const Note = ({ note, setNote, submitHandler, style, showButtons, readOnly }) => {
    return (
        <StyledNote style={style}>
            <StyledNoteInputs
                setState={setNote}
                state={note}
                elements={PATIENT_NOTE}
                readOnly={readOnly}
            />
            {showButtons ? (
                <NoteButtons submitHandler={submitHandler} />
            ) : (
                <StyledInstructorsFooter>This is Preview. No Submission is Allowed.</StyledInstructorsFooter>
            )}
        </StyledNote>
    );
};

export default Note;
