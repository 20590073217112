import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
// import { Book } from 'react-bootstrap-icons';
import Title from 'src/ui/Title';
import { utcToEstNoTime } from 'src/utils/formatDate';
import ProgressBar from 'src/ui/Charts/ProgressBar';
import useAuthContext from 'src/hooks/useAuthContext';
import placeholderImage from 'src/assets/img/placeholderImage.jpg';

const StyledScenarioList = styled(Link)`
    display: flex;
    gap: ${({ theme }) => theme.spacing.m};
    padding-top: ${({ theme }) => theme.spacing.m};
    padding-bottom: ${({ theme }) => theme.spacing.m};
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
`;
const StyledImageWrapper = styled.div`
    width: ${({ theme }) => theme.imageSize.m.width};
    height: ${({ theme }) => theme.imageSize.m.height};
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.m};
    object-fit: cover;
`;

// const StyledIconWrapper = styled.div`
//     border-radius: ${({ theme }) => theme.borderRadius.m};
//     border: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100%;
// `;
const StyledContentWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const StyledDescription = styled.p`
    margin-bottom: ${({ theme }) => theme.spacing.m};
`;
const StyledFooter = styled.div`
    margin-top: auto;
    align-self: flex-end;
    font-size: ${({ theme }) => theme.fontSize.s};
`;

const ScenarioList = ({ scenario }) => {
    const { courseId } = useParams();
    const theme = useTheme();
    const { currentUser } = useAuthContext();

    // Link to CourseScenarioPage when courseId exists, otherwise link to ScenarioPage
    const link = courseId ? `/courses/${courseId}/scenarios/${scenario.id}` : `/scenarios/${scenario.id}`;

    return (
        <StyledScenarioList to={link}>
            <StyledImageWrapper>
                {/* TODO: add image
                {scenario.image ? (*/}
                <StyledImage
                    src={placeholderImage}
                    alt=""
                />
                {/*}) : ( */}

                {/* <StyledIconWrapper>
                    <Book
                        size="50"
                        color={theme.colors.gray}
                    />
                </StyledIconWrapper> */}
                {/* )} */}
            </StyledImageWrapper>
            <StyledContentWrapper>
                <Title
                    color={theme.colors.primary}
                    component="h3"
                >
                    {scenario.title}
                </Title>
                {currentUser.isStudent && <StyledDescription>{scenario.description}</StyledDescription>}
                {currentUser.isInstructor && <StyledDescription>{scenario.patient_condition}</StyledDescription>}
                <StyledFooter>
                    {scenario.courses.map((course) => (
                        <div key={course.id}>{course.name}</div>
                    ))}
                    {/* TODO: add updated date */}
                    <div>Created at: {utcToEstNoTime(scenario.created_at)}</div>
                </StyledFooter>
            </StyledContentWrapper>
            {/* TODO: <div>Status, Score here</div> */}
            <div style={{ textAlign: 'center' }}>
                {currentUser.isInstructor ? (
                    <>
                        <Title
                            color={theme.colors.darkGray}
                            component="h4"
                        >
                            Complete Rate
                        </Title>
                        <ProgressBar
                            circleSize={130}
                            percentage={50}
                            goalPercentage={50} // TODO: this is place holder number
                        />
                    </>
                ) : (
                    currentUser.isStudent && (
                        <>
                            <Title
                                color={theme.colors.darkGray}
                                component="h4"
                            >
                                Progress{' '}
                            </Title>
                            <ProgressBar
                                circleSize={130}
                                percentage={50}
                                goalPercentage={50} // TODO: this is place holder number
                            />
                        </>
                    )
                )}
            </div>
        </StyledScenarioList>
    );
};

export default ScenarioList;
