import React from 'react';
import useAuthContext from 'src/hooks/useAuthContext';
import styled from 'styled-components';

import Container from 'src/ui/Container';
import Title from 'src/ui/Title';
import { useTheme } from 'styled-components';
import { Activity, Book, ClipboardCheck, People, Person } from 'react-bootstrap-icons';
import ProgressBar from 'src/ui/Charts/ProgressBar';

const StyledWelcomeTextSection = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.xs};
    font-size: ${({ theme }) => theme.fontSize.l};
    font-weight: ${({ theme }) => theme.fontWeight.xl};
    color: ${({ theme }) => theme.colors.darkGray};
    letter-spacing: 0.5px;
    text-transform: capitalize;
`;

const StyledWelcomeText = styled.span`
    color: ${({ theme }) => theme.colors.gray};
`;

const StyledBlocksWrapper = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.m};
`;

const StyledLeft = styled.div`
    width: 40%;
    display: flex;
    flex-flow: column;
    gap: ${({ theme }) => theme.spacing.m};
`;

const StyledSteps = styled.div`
    background-color: ${({ theme }) => theme.colors.primaryLight};
    border-radius: ${({ theme }) => theme.borderRadius.m};
    box-shadow: ${({ theme }) => theme.shadow.s};
    padding: ${({ theme }) => theme.spacing.m};

    display: flex;
    flex-flow: column;
    gap: ${({ theme }) => theme.spacing.s};
`;

const StyledStepsItem = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.s};

    font-size: ${({ theme }) => theme.fontSize.s};
`;

const StyledStepsNumber = styled.div`
    color: ${({ theme }) => theme.colors.primaryLight};
    font-size: ${({ theme }) => theme.fontSize.xl};
    font-weight: ${({ theme }) => theme.fontWeight.xl};
    text-shadow: ${({
        theme,
    }) => `-1px -1px 0 ${theme.colors.primary}, 0 -1px 0 ${theme.colors.primary}, 1px -1px 0 ${theme.colors.primary}, 1px 0 0 ${theme.colors.primary}, 1px 1px 0 ${theme.colors.primary}, 0 1px 0 ${theme.colors.primary}, -1px 1px 0 ${theme.colors.primary},
        -1px 0 0 ${theme.colors.primary}`};
`;

const StyledRight = styled.div`
    width: 60%;
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing.m};
`;

const StyledAnalyticsItem = styled.div`
    width: ${({ theme }) => `calc(50% - (${theme.spacing.l}/2))`};
    border-radius: ${({ theme }) => theme.borderRadius.m};
    padding: ${({ theme }) => theme.spacing.m};
    box-shadow: ${({ theme }) => theme.shadow.s};
    display: flex;
    flex-flow: column;
    gap: ${({ theme }) => theme.spacing.xs};
`;

const StyledIconWrapper = styled.div`
    width: 55px;
    height: 55px;
    background-color: ${({ $bgColor }) => $bgColor};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledAnalyticsTitle = styled.div`
    font-weight: ${({ theme }) => theme.fontWeight.l};
    color: ${({ theme }) => theme.colors.darkGray};
`;
const StyledAnalyticsValue = styled.div`
    font-size: 30px;
    font-weight: ${({ theme }) => theme.fontWeight.xl};
`;

const DashboardPage = () => {
    const { currentUser } = useAuthContext();
    const theme = useTheme();

    return (
        <Container>
            <StyledWelcomeTextSection>
                <StyledWelcomeText>Welcome </StyledWelcomeText>
                {currentUser.nickname}!
            </StyledWelcomeTextSection>

            <StyledBlocksWrapper>
                <StyledLeft>
                    {currentUser.isInstructor && (
                        <>
                            <StyledSteps>
                                <Title
                                    component="h3"
                                    color={theme.colors.primary}
                                >
                                    Basic Usage
                                </Title>
                                <StyledStepsItem>
                                    <StyledStepsNumber>1</StyledStepsNumber>
                                    <div>Create Courses</div>
                                </StyledStepsItem>
                                <StyledStepsItem>
                                    <StyledStepsNumber>2</StyledStepsNumber>
                                    <div>Create Scenarios (Auto generate available!)</div>
                                </StyledStepsItem>
                                <StyledStepsItem>
                                    <StyledStepsNumber>3</StyledStepsNumber>
                                    <div>Add Students and Scenarios to the Course</div>
                                </StyledStepsItem>
                            </StyledSteps>

                            <StyledSteps>
                                <Title
                                    component="h3"
                                    color={theme.colors.primary}
                                >
                                    Test Your Scenario
                                </Title>
                                <StyledStepsItem>
                                    <StyledStepsNumber>1</StyledStepsNumber>
                                    <div>Go to scenario page</div>
                                </StyledStepsItem>
                                <StyledStepsItem>
                                    <StyledStepsNumber>2</StyledStepsNumber>
                                    <div>Go to preview</div>
                                </StyledStepsItem>
                            </StyledSteps>

                            <StyledSteps>
                                <Title
                                    component="h3"
                                    color={theme.colors.primary}
                                >
                                    Give Feedback
                                </Title>
                                <StyledStepsItem>
                                    <StyledStepsNumber>1</StyledStepsNumber>
                                    <div>Go to course</div>
                                </StyledStepsItem>
                                <StyledStepsItem>
                                    <StyledStepsNumber>2</StyledStepsNumber>
                                    <div>Open a scenario</div>
                                </StyledStepsItem>
                                <StyledStepsItem>
                                    <StyledStepsNumber>3</StyledStepsNumber>
                                    <div>Access attempts at the bottom of the screen</div>
                                </StyledStepsItem>
                                <StyledStepsItem>
                                    <StyledStepsNumber>4</StyledStepsNumber>
                                    <div>Provide feedback by clicking the feedback button on the right. (Auto generate available!)</div>
                                </StyledStepsItem>
                            </StyledSteps>
                        </>
                    )}

                    {currentUser.isStudent && (
                        <>
                            <StyledSteps>
                                <Title
                                    component="h3"
                                    color={theme.colors.primary}
                                >
                                    Submit Scenario Attempt
                                </Title>
                                <StyledStepsItem>
                                    <StyledStepsNumber>1</StyledStepsNumber>
                                    <div>Go to course page</div>
                                </StyledStepsItem>
                                <StyledStepsItem>
                                    <StyledStepsNumber>2</StyledStepsNumber>
                                    <div>Pick a scenario</div>
                                </StyledStepsItem>
                                <StyledStepsItem>
                                    <StyledStepsNumber>3</StyledStepsNumber>
                                    <div>Go to attempt page by clicking the Start Assessment button on the top right</div>
                                </StyledStepsItem>
                            </StyledSteps>

                            <StyledSteps>
                                <Title
                                    component="h3"
                                    color={theme.colors.primary}
                                >
                                    Look Feedback from your instructor
                                </Title>
                                <StyledStepsItem>
                                    <StyledStepsNumber>1</StyledStepsNumber>
                                    <div>Go to course</div>
                                </StyledStepsItem>
                                <StyledStepsItem>
                                    <StyledStepsNumber>2</StyledStepsNumber>
                                    <div>Open a scenario</div>
                                </StyledStepsItem>
                                <StyledStepsItem>
                                    <StyledStepsNumber>3</StyledStepsNumber>
                                    <div>Access attempts at the bottom of the screen</div>
                                </StyledStepsItem>
                                <StyledStepsItem>
                                    <StyledStepsNumber>4</StyledStepsNumber>
                                    <div>Find feedback by clicking the feedback button on the right.</div>
                                </StyledStepsItem>
                            </StyledSteps>
                        </>
                    )}
                </StyledLeft>
                <StyledRight>
                    <StyledAnalyticsItem>
                        <StyledIconWrapper $bgColor={theme.colors.warningLight2}>
                            <People
                                size={30}
                                color={theme.colors.warning}
                            />
                        </StyledIconWrapper>

                        <StyledAnalyticsTitle>Number of Courses</StyledAnalyticsTitle>
                        <StyledAnalyticsValue>12</StyledAnalyticsValue>
                    </StyledAnalyticsItem>
                    {currentUser.isInstructor && (
                        <StyledAnalyticsItem>
                            <StyledIconWrapper $bgColor={theme.colors.primaryLight}>
                                <Person
                                    size={30}
                                    color={theme.colors.primary}
                                />
                            </StyledIconWrapper>
                            <StyledAnalyticsTitle>Number of Students</StyledAnalyticsTitle>
                            <StyledAnalyticsValue>24</StyledAnalyticsValue>
                        </StyledAnalyticsItem>
                    )}
                    {currentUser.isStudent && (
                        <StyledAnalyticsItem>
                            <StyledIconWrapper $bgColor={theme.colors.primaryLight}>
                                <ClipboardCheck
                                    size={30}
                                    color={theme.colors.primary}
                                />
                            </StyledIconWrapper>
                            <StyledAnalyticsTitle>Number of Submission</StyledAnalyticsTitle>
                            <StyledAnalyticsValue>3</StyledAnalyticsValue>
                        </StyledAnalyticsItem>
                    )}

                    <StyledAnalyticsItem>
                        <StyledIconWrapper $bgColor={theme.colors.secondaryLight}>
                            <Book
                                size={30}
                                color={theme.colors.secondary}
                            />
                        </StyledIconWrapper>
                        <StyledAnalyticsTitle>Number of Scenarios</StyledAnalyticsTitle>
                        <StyledAnalyticsValue>31</StyledAnalyticsValue>
                    </StyledAnalyticsItem>
                    <StyledAnalyticsItem>
                        <StyledIconWrapper $bgColor={theme.colors.successLight2}>
                            <Activity
                                size={30}
                                color={theme.colors.success}
                            />
                        </StyledIconWrapper>
                        <StyledAnalyticsTitle>Recent Activity</StyledAnalyticsTitle>
                        <StyledAnalyticsValue>24</StyledAnalyticsValue>
                    </StyledAnalyticsItem>
                    <StyledAnalyticsItem style={{ alignItems: 'center' }}>
                        <StyledAnalyticsTitle>Attempt Submission Rate</StyledAnalyticsTitle>
                        <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                            <ProgressBar
                                circleSize={150}
                                percentage={50}
                                goalPercentage={50} // TODO: this is place holder number
                            />
                        </div>
                    </StyledAnalyticsItem>
                    <StyledAnalyticsItem style={{ alignItems: 'center' }}>
                        <StyledAnalyticsTitle>Feedback Rate</StyledAnalyticsTitle>
                        <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                            <ProgressBar
                                circleSize={150}
                                percentage={35}
                                goalPercentage={50} // TODO: this is place holder number
                            />
                        </div>
                    </StyledAnalyticsItem>
                </StyledRight>
            </StyledBlocksWrapper>
        </Container>
    );
};

export default DashboardPage;
