import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from 'src/constants/endpoints';
import useFetch from 'src/hooks/useFetch';
import ProgressBar from 'src/ui/Charts/ProgressBar';
import Title from 'src/ui/Title';
import styled, { useTheme } from 'styled-components';
import Table from 'src/ui/Charts/Table';
import { utcToEst } from 'src/utils/formatDate';
import SpinnerIcon from 'src/ui/SpinnerIcon';
import NoResultScreen from 'src/ui/NoResultScreen';

const StyledCourseScenarioAnalytics = styled.div`
    border-top: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
    padding-top: ${({ theme }) => theme.spacing.l};
    padding-bottom: ${({ theme }) => theme.spacing.l};
`;

const StyledTitle = styled(Title)`
    text-align: center;
    margin-bottom: ${({ theme }) => theme.spacing.l};
`;

const StyledScenarioAnalytics = styled.div`
    display: flex;
`;
const StyledChart = styled.div`
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CourseScenarioAnalytics = () => {
    const theme = useTheme();
    const { scenarioId, courseId } = useParams();
    const navigate = useNavigate();

    const [selectedCourseAttempts, setSelectedCourseAttempts] = useState(null);

    const { fetchData: attemptFetchData, isLoading: attemptIsLoading } = useFetch(`${config.url.ATTEMPTS}?scenarioId=${scenarioId}&courseId=${courseId}`);

    useEffect(() => {
        attemptFetchData({ method: 'GET' }, (data) => {
            setSelectedCourseAttempts(data);
        });
    }, [attemptFetchData]);

    return attemptIsLoading ? (
        <SpinnerIcon size={30} />
    ) : (
        <StyledCourseScenarioAnalytics>
            <StyledTitle
                component={'h3'}
                color={theme.colors.primary}
            >
                Scenario Analytics & Submission
            </StyledTitle>
            {selectedCourseAttempts?.results.length > 0 ? (
                <StyledScenarioAnalytics>
                    <StyledChart>
                        <div>Complete Rate</div>
                        <ProgressBar
                            circleSize={200}
                            percentage={50}
                            goalPercentage={50} // TODO: this is place holder number
                        />
                    </StyledChart>

                    <Table
                        columns={[
                            {
                                accessorKey: 'id',
                                header: 'ATTEMPT ID', // header property can take string or a function that returns a React component
                                size: 80,
                                cell: (props) => <div>{props.getValue()}</div>, // cell property can take a function that can return JSX. It also has access to table, row, column, cell, getValue, and renderValue as param
                            },
                            {
                                accessorKey: 'user.first_name',
                                header: 'FIRST',
                                cell: (props) => <div>{props.getValue()}</div>,
                            },
                            {
                                accessorKey: 'user.last_name',
                                header: 'LAST',
                                cell: (props) => <div>{props.getValue()}</div>,
                            },
                            {
                                accessorKey: 'updated_at',
                                header: 'SUBMISSION DATE',
                                size: 220,
                                cell: (props) => <div>{utcToEst(props.getValue())}</div>,
                            },
                            {
                                accessorKey: 'details',
                                header: 'DETAILS',
                                size: 20,
                                cell: (props) => (
                                    <button
                                        variant="text"
                                        color="black"
                                        style={{
                                            color: theme.colors.primary,
                                            fontSize: theme.fontSize.s,
                                            fontWeight: theme.fontWeight.l,
                                        }}
                                        onClick={() => {
                                            navigate(`/attempts/${props.row.original.id}`);
                                        }}
                                    >
                                        Details
                                    </button>
                                ),
                            },
                        ]}
                        data={selectedCourseAttempts?.results}
                    />
                </StyledScenarioAnalytics>
            ) : (
                <NoResultScreen
                    largeText={'No Submission yet.'}
                    text={`There is no submission yet.`}
                />
            )}
        </StyledCourseScenarioAnalytics>
    );
};

export default CourseScenarioAnalytics;
