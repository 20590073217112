import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { PlusCircleFill } from 'react-bootstrap-icons';
import useAuthContext from 'src/hooks/useAuthContext';
import useFetch from 'src/hooks/useFetch';
import { config } from 'src/constants/endpoints';
import CourseList from 'src/components/courses/CourseList';
import Container from 'src/ui/Container';
import LocalNav from 'src/ui/LocalNav';
import SpinnerScreen from 'src/ui/SpinnerScreen';
import IconLabelLink from 'src/ui/IconLabelLink';
import NoResultScreen from 'src/ui/NoResultScreen';

const StyledCourseLists = styled.div`
    display: flex;
    flex-direction: column;
`;

const CourseListsPage = () => {
    const theme = useTheme();
    const { currentUser } = useAuthContext();
    const { data, isLoading, isSuccess, fetchData } = useFetch(config.url.COURSES);
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (isSuccess) {
            setCourses(data.results);
        }
    }, [isSuccess, data]);

    if (isLoading) {
        return <SpinnerScreen />;
    }

    return (
        <Container style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <LocalNav
                    label="Back to Dashboard"
                    to={'/'}
                />
                {currentUser.isInstructor && (
                    <IconLabelLink
                        to="/courses/create"
                        color={theme.colors.primary}
                        isBold={true}
                    >
                        <PlusCircleFill color={theme.colors.primary} /> Create New Course
                    </IconLabelLink>
                )}
            </div>

            {courses.length > 0 ? (
                <StyledCourseLists>
                    {courses.map((course) => (
                        <CourseList
                            key={course.id}
                            course={course}
                        />
                    ))}
                </StyledCourseLists>
            ) : (
                <NoResultScreen
                    largeText={'No Course is created yet.'}
                    text={currentUser.isInstructor && `Please create a new course from "Create New Course" button on the top right.`}
                    style={{ flex: 1, height: '100%' }}
                />
            )}
        </Container>
    );
};

export default CourseListsPage;
