import React from 'react';
import styled from 'styled-components';
import { Label } from './Label';
import scrollBarStyle from 'src/styles/scrollBarStyle';

const StyledTextareaWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.fontSize.s};
`;

const StyledTextarea = styled.textarea`
    ${scrollBarStyle}
    border-radius: ${({ theme }) => theme.borderRadius.m};
    padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.s};
    border: ${({ theme }) => theme.colors.gray} 1px solid;
    line-height: 1.5 !important; // Need important to overwrite default styling
    max-width: 800px;
    font: inherit;

    &:focus,
    &:active {
        outline: none;
    }

    /* For Chrome auto fill background */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px #fff inset !important;
    }
`;

const StyledErrorMessage = styled.span`
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
    color: ${({ theme }) => theme.colors.warning};
    font-size: ${({ theme }) => theme.fontSize.s};
`;

const Textarea = (props) => {
    return (
        <StyledTextareaWrapper
            className={props.className}
            style={props.style}
        >
            {props.label && (
                <Label
                    htmlFor={props.id}
                    isRequired={props.isRequired}
                >
                    {props.label}
                </Label>
            )}
            <StyledTextarea
                {...props}
                {...props.register}
            />
            {props.errorMessage && <StyledErrorMessage>{props.errorMessage}</StyledErrorMessage>}
        </StyledTextareaWrapper>
    );
};

export default Textarea;
