export const SCENARIO_ELEMENTS = [
    {
        component: 'input',
        label: 'Title',
        id: 'title',
        property: 'title',
        isRequired: true,
    },
    {
        component: 'textarea',
        label: 'Description',
        id: 'description',
        property: 'description',
        isRequired: true,
    },

    {
        component: 'input',
        label: 'Patient Name',
        id: 'patient_name',
        property: 'patient_name',
        isRequired: true,
    },
    {
        component: 'input',
        inputType: 'date',
        label: 'Patient Date of Birth',
        id: 'patient_dob',
        property: 'patient_dob',
        isRequired: true,
    },

    {
        component: 'textarea',
        rows: 2,
        label: 'Patient Condition',
        id: 'patient_condition',
        property: 'patient_condition',
        isRequired: true,
    },
    {
        component: 'textarea',
        rows: 3,
        label: 'Signs and Symptoms',
        id: 'patient_symptoms',
        property: 'patient_symptoms',
        isRequired: true,
    },
    // {
    //     component: 'textarea',
    //     rows: 13,
    //     label: 'History of Present Illness',
    //     id: 'historyOfPresentIllness',
    //     property: 'historyOfPresentIllness',
    // },
    // {
    //     component: 'textarea',
    //     rows: 8,
    //     label: 'Surgical History',
    //     id: 'surgicalHistory',
    //     property: 'surgicalHistory',
    // },
    // {
    //     component: 'textarea',
    //     rows: 8,
    //     label: 'Medical History',
    //     id: 'medicalHistory',
    //     property: 'medicalHistory',
    // },
    // {
    //     component: 'textarea',
    //     rows: 3,
    //     label: 'Social History',
    //     id: 'socialHistory',
    //     property: 'socialHistory',
    // },
    // {
    //     component: 'textarea',
    //     rows: 3,
    //     label: 'Family History',
    //     id: 'familyHistory',
    //     property: 'familyHistory',
    // },
    {
        component: 'textarea',
        rows: 3,
        label: 'Patient HPI',
        id: 'patient_hpi',
        property: 'patient_hpi',
        isRequired: true,
    },
    {
        component: 'textarea',
        rows: 3,
        label: 'Allergies',
        id: 'patient_allergies',
        property: 'patient_allergies',
        isRequired: true,
    },
    // {
    //     component: 'textarea',
    //     rows: 18,
    //     label: 'Medications',
    //     id: 'medications',
    //     property: 'medications',
    // },
];
