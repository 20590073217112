import React from 'react';
import { useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import styled from 'styled-components';

const StyledTable = styled.table`
    width: 100%;
    text-align: left;
    border-collapse: collapse;
`;

const StyledTh = styled.th`
    position: relative;
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
    padding: ${({ theme }) => theme.spacing.xs};
    font-weight: ${({ theme }) => theme.fontWeight.l};
    font-size: ${({ theme }) => theme.fontSize.xs};
    & .resizer {
        position: absolute;
        top: 0;
        right: 5px;
        width: 4px;
        height: 100%;
        opacity: 0;
        background-color: ${({ theme }) => theme.colors.primary};
        &.isResizing {
            background-color: ${({ theme }) => theme.colors.primaryLight};
        }
        cursor: col-resize;
    }
    &:hover .resizer {
        opacity: 1;
    }
`;

const StyledBodyTd = styled.td`
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
    padding: ${({ theme }) => theme.spacing.xs};
    font-weight: ${({ theme }) => theme.fontWeight.m};
`;

const Table = ({ data, columns }) => {
    // useReactTable contains all helper function
    const table = useReactTable({
        data, // Data to display on the table
        columns, // An array to generate columns
        getCoreRowModel: getCoreRowModel(), // To allow row modal to work under the hood within the react table
        columnResizeMode: 'onChange',
    });

    return (
        <StyledTable>
            <tbody>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr>
                        {headerGroup.headers.map((header) => (
                            <StyledTh style={{ width: `${header.getSize()}px` }}>
                                {header.column.columnDef.header}
                                <div
                                    onMouseDown={header.getResizeHandler()}
                                    onTouchStart={header.getResizeHandler()}
                                    className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                                />
                            </StyledTh>
                        ))}
                    </tr>
                ))}
                {table.getRowModel().rows.map((row) => (
                    <tr>
                        {row.getVisibleCells().map((cell) => (
                            <StyledBodyTd
                                style={{
                                    width: `${cell.column.getSize()}px`,
                                }}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </StyledBodyTd>
                        ))}
                    </tr>
                ))}
            </tbody>
        </StyledTable>
    );
};

export default Table;
