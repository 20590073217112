import { css } from 'styled-components';

export const itemWrapperStyle = css`
    margin-bottom: ${({ theme }) => theme.spacing.s};
    display: flex;
    align-items: center;
`;

export const iconWrapperStyle = css`
    display: flex;
    align-items: center;
    padding-left: 8px;
    width: 42px;
    height: 26px;
    transition: width 0.3s ease-out 0.1s, max-width 0.3s ease-out 0.1s;
    & svg {
        width: 20px;
        height: 20px;
    }
    &.miniMized {
        width: 36px;
    }
`;

export const textStyle = css`
    padding-left: 0.1em;
    padding-right: 0.1em;
    transition: opacity 0.3s ease-out 0.1s, max-width 0.3s ease-out 0.1s;
    &.miniMized {
        opacity: 0;
        max-width: 0;
        padding-left: 0;
        padding-right: 0;
    }
`;
