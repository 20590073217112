import React, { useCallback, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useFetch from 'src/hooks/useFetch';
import ChatScreenSection from 'src/components/attempts/ChatScreenSection';
import Note from 'src/components/attempts/Note';
// import LocalNav from 'src/ui/LocalNav';
import { config } from 'src/constants/endpoints';
import { useNavigate, useParams } from 'react-router-dom';
import SpinnerScreen from 'src/ui/SpinnerScreen';
import Textarea from 'src/ui/form-ui/Textarea';
import Button from 'src/ui/Button';
import useAuthContext from 'src/hooks/useAuthContext';
import UserAvatar from 'src/ui/UserAvatar';
import { ArrowClockwise, ChatRightText } from 'react-bootstrap-icons';
import Tabs from 'src/ui/Tabs';
import TabPane from 'src/ui/Tabs/TabPane';
import { useDomMeasurement } from 'src/hooks/useDomDemention';
import Title from 'src/ui/Title';
import { utcToEst } from 'src/utils/formatDate';
import { SCENARIO_ELEMENTS } from 'src/constants/formElements/scenarios';

const StyledAttemptBasicInfoSection = styled.div`
    width: 100%;
    padding-bottom: ${({ theme }) => theme.spacing.s};
    & strong {
        color: ${({ theme }) => theme.colors.darkGray};
    }
`;

const StyledAttemptBasicInfoItem = styled.div`
    font-size: ${({ theme }) => theme.fontSize.s};
`;

const StyledAttemptSubmissionSection = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.xs};
`;

const StyledLeft = styled.div`
    width: 50%;
`;
const StyledRight = styled.div`
    width: 50%;
    border-left: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
    padding-left: ${({ theme }) => theme.spacing.s};
`;
const StyledAttemptFeedbackSection = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 450px;
    background: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => `${theme.borderRadius.m} 0 0 ${theme.borderRadius.m}`};
    box-shadow: ${({ theme }) => theme.shadow.s};
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.m};
    transform: ${({ $isFeedbackDrawerOpen }) => ($isFeedbackDrawerOpen ? 'none' : 'translateX(100%)')};
    transition: transform 0.3s ease-in-out;
`;

const StyledFeedbackContent = styled.div`
    flex: 1;
    display: flex;
    gap: ${({ theme }) => theme.spacing.s};
`;
const StyledFeedbackButtonWrapper = styled.div`
    align-self: flex-end;
    margin-top: ${({ theme }) => theme.spacing.m};
    display: flex;
    gap: ${({ theme }) => theme.spacing.xs};
`;

const StyledScenarioDetailItem = styled.div`
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
    padding-top: ${({ theme }) => theme.spacing.s};
    padding-bottom: ${({ theme }) => theme.spacing.s};
    & strong {
        color: ${({ theme }) => theme.colors.darkGray};
        margin-bottom: ${({ theme }) => theme.spacing.xs};
    }
`;

const StyledDrawerToggleButton = styled.button`
    position: absolute;
    top: ${({ theme }) => `${theme.spacing.s}`};
    left: ${({ theme }) => `-${theme.spacing.s}`};
    transform: translateX(-100%);
    background-color: ${({ theme }) => theme.colors.primaryLight};
    padding: ${({ theme }) => theme.spacing.s};
    border-radius: ${({ theme }) => theme.borderRadius.m};
    box-shadow: ${({ theme }) => theme.shadow.s};
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
`;

const AttemptDetailPage = () => {
    const theme = useTheme();
    const { attemptId } = useParams();
    const navigate = useNavigate();

    const schema = yup.object().shape({
        feedback: yup.string('Please enter feedback message').max(5000, 'Text exceeds 5000 characters limit.').required('Feedback message is required field'),
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schema),
    });

    const { height: baseInfoHeight, ref: basicInfoRef } = useDomMeasurement();

    // API Call: Get Attempt Submission Data (CHAT HISTORY, NOTE, SCENARIO)
    const { data: attemptData, fetchData: getAttemptData, isLoading: getAttemptDataIsLoading } = useFetch(`${config.url.ATTEMPTS}${attemptId}`);

    // API Call: Get Feedback from instructor
    const { fetchData: getAttemptFeedbackData, isLoading: getAttemptFeedbackDataIsLoading } = useFetch(`${config.url.ATTEMPTS}${attemptId}/feedback`);

    // API Call: Generate Auto Feedback
    const { fetchData: getGenerateAttemptFeedbackData, isLoading: getGenerateAttemptFeedbackIsLoading } = useFetch(
        `${config.url.ATTEMPTS}${attemptId}/generate-feedback/`
    );
    // API Call: Post Feedback
    const { fetchData: submitAttemptFeedbackData, isLoading: submitAttemptFeedbackIsLoading } = useFetch(`${config.url.ATTEMPTS}${attemptId}/feedback/`);

    const { currentUser } = useAuthContext();

    const [isAttemptFeedback, setIsAttemptFeedback] = useState(false);

    // Drawer open/close
    const [isFeedbackDrawerOpen, setIsFeedbackDrawerOpen] = useState(false);

    const getSetAttemptFeedbackData = useCallback(() => {
        getAttemptFeedbackData({}, (data) => {
            if (data.length > 0) {
                setValue('feedback', data[0].feedback);
                setIsAttemptFeedback(true);
            }
        });
    }, [getAttemptFeedbackData, setValue]);

    useEffect(() => {
        // Set Chat and Note feedback data for initial load
        getAttemptData({}, null, (error) => {
            // Redirecting when attempt id is invalid
            if (error.message === '404') {
                navigate('/not-found');
            }
        });

        // Set Attempt feedback data for initial load (Currently only first feedback will be displayed)
        getSetAttemptFeedbackData();

        // Don't want to add navigate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAttemptData, getSetAttemptFeedbackData]);

    const feedbackSubmitHandler = (attemptFeedback) => {
        const fetchMethod = isAttemptFeedback ? 'PATCH' : 'POST';
        submitAttemptFeedbackData({ method: fetchMethod, body: JSON.stringify(attemptFeedback) }, (data) => {
            // TODO: set success or error message
            getSetAttemptFeedbackData();
        });
    };

    const isAnySubmitting = getGenerateAttemptFeedbackIsLoading || submitAttemptFeedbackIsLoading;

    if (getAttemptDataIsLoading || getAttemptFeedbackDataIsLoading) {
        return <SpinnerScreen />;
    }

    return (
        <>
            {/* TODO: <LocalNav
                to={`/#`}
                label="Back"
            /> */}
            <StyledAttemptBasicInfoSection ref={basicInfoRef}>
                <Title
                    component={'h4'}
                    color={theme.colors.primary}
                >
                    {attemptData?.course?.name} - {attemptData?.scenario?.title}
                </Title>
                <StyledAttemptBasicInfoItem>
                    <strong>Student's Name: </strong>
                    {attemptData?.user?.first_name} {attemptData?.user?.last_name}{' '}
                </StyledAttemptBasicInfoItem>
                <StyledAttemptBasicInfoItem>
                    <strong>Submission Time: </strong>
                    {utcToEst(attemptData?.updated_at)}
                </StyledAttemptBasicInfoItem>
            </StyledAttemptBasicInfoSection>
            <StyledAttemptSubmissionSection>
                <StyledLeft>
                    <ChatScreenSection
                        style={{ height: `calc(100vh - (${theme.spacing.m})*2 - ${baseInfoHeight}px)` }}
                        messages={attemptData?.chat_history?.history || []}
                        isAttemptDetailPage={true}
                    />
                </StyledLeft>
                <StyledRight>
                    <Note
                        style={{ height: `calc(100vh - (${theme.spacing.m})*2 -  ${baseInfoHeight}px)` }}
                        note={attemptData.note || []}
                        setNote={null}
                        showButtons={false}
                        readOnly={true}
                    />
                </StyledRight>
            </StyledAttemptSubmissionSection>
            <StyledAttemptFeedbackSection $isFeedbackDrawerOpen={isFeedbackDrawerOpen}>
                <Tabs>
                    <TabPane title="Feedback">
                        <form
                            onSubmit={handleSubmit(feedbackSubmitHandler)}
                            style={{ height: '100%', display: 'flex', flexFlow: 'column' }}
                        >
                            <StyledFeedbackContent>
                                {/* TODO: Need to change to student user avatar */}
                                <UserAvatar
                                    style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                                    specificImageFor={'instructors'}
                                />
                                {/* Student view - No feedback yet */}
                                {currentUser.isStudent && isAttemptFeedback && (
                                    <Textarea
                                        register={{ ...register('feedback') }}
                                        readOnly={true}
                                        style={{ flex: 1, height: '100%' }}
                                    />
                                )}
                                {/* Student view - Width Feedback */}
                                {currentUser.isStudent && !isAttemptFeedback && <p>No Feedback from instructor yet.</p>}

                                {currentUser.isInstructor &&
                                    (isAnySubmitting ? (
                                        <div style={{ flex: 1, height: '100%' }}>
                                            <SpinnerScreen />
                                        </div>
                                    ) : (
                                        <Textarea
                                            register={{ ...register('feedback') }}
                                            errorMessage={errors.feedback?.message}
                                            id="feedback"
                                            readOnly={isAttemptFeedback} // TODO: modify this when edit feedback functionality is added
                                            style={{ flex: 1, height: '100%' }}
                                        />
                                    ))}
                            </StyledFeedbackContent>

                            {/* Instructor view - button is disabled whether feedback exists or not */}
                            {currentUser.isInstructor && (
                                <>
                                    {
                                        // TODO: modify this when edit feedback functionality is added
                                        !isAttemptFeedback && (
                                            <StyledFeedbackButtonWrapper>
                                                <Button
                                                    color="secondaryLight"
                                                    variant="contained"
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        getGenerateAttemptFeedbackData({}, (data) => {
                                                            setValue('feedback', data.feedback);
                                                        });
                                                    }}
                                                    disabled={isAnySubmitting}
                                                >
                                                    <ArrowClockwise /> Generate Feedback
                                                </Button>

                                                <Button
                                                    color="primaryLight"
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={isAnySubmitting}
                                                >
                                                    Submit
                                                </Button>
                                            </StyledFeedbackButtonWrapper>
                                        )
                                    }
                                </>
                            )}
                        </form>
                    </TabPane>

                    <TabPane title="Scenario Detail">
                        {attemptData?.scenario ? (
                            <div>
                                {SCENARIO_ELEMENTS.map((item) => {
                                    return (
                                        <StyledScenarioDetailItem key={item.id}>
                                            <strong>{item.label}:</strong>
                                            <div>{attemptData?.scenario?.[item.id]}</div>
                                        </StyledScenarioDetailItem>
                                    );
                                })}
                            </div>
                        ) : (
                            <div>Scenario detail is not released yet.</div>
                        )}
                    </TabPane>
                </Tabs>
                <StyledDrawerToggleButton onClick={() => setIsFeedbackDrawerOpen((prev) => !prev)}>
                    <ChatRightText
                        size={20}
                        color={theme.colors.primary}
                    />
                    Feedback
                </StyledDrawerToggleButton>
            </StyledAttemptFeedbackSection>
        </>
    );
};

export default AttemptDetailPage;
