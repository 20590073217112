import { useEffect, useRef, useState } from 'react';

export const useDomMeasurement = (callback) => {
    const ref = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const setHeightValue = () => {
            if (ref.current?.clientHeight) {
                setHeight(ref.current.clientHeight);
            } else {
                setHeight(0);
            }
            if (callback) {
                callback(height);
            }
        };
        setHeightValue();
        window.addEventListener('resize', setHeightValue);

        return () => {
            window.removeEventListener('resize', setHeightValue);
        };
        // eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
    }, [ref]);

    return { ref, height };
};
