import { Link } from 'react-router-dom';
import React from 'react';
import styled, { css } from 'styled-components';

// Base Buttons
const BaseStyle = css`
    display: inline-flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
    min-width: 70px;
    border-radius: ${({ theme }) => theme.borderRadius.l};
    padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.s};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.fontWeight.l};
    font-size: ${({ theme }) => theme.fontSize.s};
    letter-spacing: 1px;
    transition: 0.3s ease;
    cursor: pointer;
`;
const BaseContainedStyle = css`
    ${BaseStyle}
    border-width: 0px;
`;

const BaseOutlinedStyle = css`
    ${BaseStyle}
    border-width: 1px;
    border-style: solid;
`;

const BaseTextButton = css`
    ${BaseStyle}
    border-width: 0px;
    font-weight: ${({ theme }) => theme.fontWeight.m};
    letter-spacing: initial;
    /* padding: ${({ theme }) => `${theme.spacing.xs} 0`}; */
`;

// Contained Primary
const PrimaryContainedStyle = css`
    ${BaseContainedStyle}
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
        background-color: ${({ theme }) => theme.colors.primaryLight};
    }
`;
const PrimaryContainedButton = styled.button`
    ${PrimaryContainedStyle}
`;
const PrimaryContainedLink = styled(Link)`
    ${PrimaryContainedStyle}
`;
// Contained Primary Light
const PrimaryLightContainedStyle = css`
    ${BaseContainedStyle}
    background-color: ${({ theme }) => theme.colors.primaryLight};
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.primary};
    }
`;
const PrimaryLightContainedButton = styled.button`
    ${PrimaryLightContainedStyle}
`;
const PrimaryLightContainedLink = styled(Link)`
    ${PrimaryLightContainedStyle}
`;

// Contained Secondary
const SecondaryContainedStyle = css`
    ${BaseContainedStyle}
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    &:hover {
        color: ${({ theme }) => theme.colors.secondary};
        background-color: ${({ theme }) => theme.colors.secondaryLight};
    }
`;
const SecondaryContainedButton = styled.button`
    ${SecondaryContainedStyle}
`;
const SecondaryContainedLink = styled(Link)`
    ${SecondaryContainedStyle}
`;

// Contained Secondary Light
const SecondaryLightContainedStyle = css`
    ${BaseContainedStyle}
    background-color: ${({ theme }) => theme.colors.secondaryLight};
    color: ${({ theme }) => theme.colors.secondary};
    &:hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.secondary};
    }
`;
const SecondaryLightContainedButton = styled.button`
    ${SecondaryLightContainedStyle}
`;
const SecondaryLightContainedLink = styled(Link)`
    ${SecondaryLightContainedStyle}
`;

// Contained Warning
const WarningContainedStyle = css`
    ${BaseContainedStyle}
    background-color: ${({ theme }) => theme.colors.warningLight};
    color: ${({ theme }) => theme.colors.warning};
    &:hover {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.warning};
    }
`;
const WarningContainedButton = styled.button`
    ${WarningContainedStyle}
`;
const WarningContainedLink = styled(Link)`
    ${WarningContainedStyle}
`;

// Outlined Primary
const PrimaryOutlinedStyle = css`
    ${BaseOutlinedStyle}
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    &:hover {
        background-color: ${({ theme }) => theme.colors.primaryLight};
    }
`;

const PrimaryOutlinedButton = styled.button`
    ${PrimaryOutlinedStyle}
`;
// Outlined Success
const SuccessOutlinedStyle = css`
    ${BaseOutlinedStyle}
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.success};
    color: ${({ theme }) => theme.colors.success};
    &:hover {
        background-color: ${({ theme }) => theme.colors.successLight};
    }
`;

const SuccessOutlinedButton = styled.button`
    ${SuccessOutlinedStyle}
`;

// // Text
const BlackTextStyle = css`
    ${BaseTextButton}
    background-color: inherit;
    color: ${({ theme }) => theme.colors.black};
    &:hover {
        text-decoration: underline;
    }
`;

const BlackTextButton = styled.button`
    ${BlackTextStyle}
`;
// const BlackTextLink = styled(Link)`
//     ${BlackTextStyle}
// `;

// OutLined
// const BlueOutLinedButton = styled(BaseOutlinedButton)`
//     background-color: transparent;
//     border-color: ${({ theme }) => theme.colors.blue};
//     color: ${({ theme }) => theme.colors.blue};
//     &:hover {
//         background-color: ${({ theme }) => theme.colors.lightBlue};
//     }
// `;

// Disabled
const DisabledButton = styled.button`
    ${BaseContainedStyle}
    background-color: ${({ theme }) => theme.colors.gray};
    color: ${({ theme }) => theme.colors.white};
    cursor: inherit;
`;

const Button = (props) => {
    switch (true) {
        case props.disabled === true:
            return <DisabledButton {...props}>{props.children}</DisabledButton>;
        // Contained Primary
        case props.variant === 'contained' && props.color === 'primary' && props.to !== undefined:
            return <PrimaryContainedLink {...props}>{props.children}</PrimaryContainedLink>;
        case props.variant === 'contained' && props.color === 'primary':
            return <PrimaryContainedButton {...props}>{props.children}</PrimaryContainedButton>;
        // Contained Primary Light
        case props.variant === 'contained' && props.color === 'primaryLight' && props.to !== undefined:
            return <PrimaryLightContainedLink {...props}>{props.children}</PrimaryLightContainedLink>;
        case props.variant === 'contained' && props.color === 'primaryLight':
            return <PrimaryLightContainedButton {...props}>{props.children}</PrimaryLightContainedButton>;
        // Contained Secondary
        case props.variant === 'contained' && props.color === 'secondary' && props.to !== undefined:
            return <SecondaryContainedLink {...props}>{props.children}</SecondaryContainedLink>;
        case props.variant === 'contained' && props.color === 'secondary':
            return <SecondaryContainedButton {...props}>{props.children}</SecondaryContainedButton>;
        // Contained Secondary Light
        case props.variant === 'contained' && props.color === 'secondaryLight' && props.to !== undefined:
            return <SecondaryLightContainedLink {...props}>{props.children}</SecondaryLightContainedLink>;
        case props.variant === 'contained' && props.color === 'secondaryLight':
            return <SecondaryLightContainedButton {...props}>{props.children}</SecondaryLightContainedButton>;
        // Contained Warning
        case props.variant === 'contained' && props.color === 'warning' && props.to !== undefined:
            return <WarningContainedLink {...props}>{props.children}</WarningContainedLink>;
        case props.variant === 'contained' && props.color === 'warning':
            return <WarningContainedButton {...props}>{props.children}</WarningContainedButton>;

        // Outlined Primary
        case props.variant === 'outlined' && props.color === 'primary':
            return <PrimaryOutlinedButton {...props}>{props.children}</PrimaryOutlinedButton>;
        case props.variant === 'outlined' && props.color === 'success':
            return <SuccessOutlinedButton {...props}>{props.children}</SuccessOutlinedButton>;

        case props.variant === 'text' && props.color === 'black':
            return <BlackTextButton {...props}>{props.children}</BlackTextButton>;

        default:
            return <button {...props}>{props.children}</button>;
    }
};

export default Button;
