import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { X } from 'react-bootstrap-icons';
import IconButton from 'src/ui/IconButton';
import scrollBarStyle from 'src/styles/scrollBarStyle';

const StyledModalContent = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 600px;
    min-height: 200px;
    width: ${({ theme, isLarge }) => (isLarge ? theme.breakPoints.m : 'auto')};
    height: ${({ theme, isLarge }) => (isLarge ? `calc(100% - ${theme.spacing.m}*2)` : 'auto')};
    border-radius: ${({ theme }) => theme.borderRadius.m};
    background-color: ${({ theme }) => `${theme.colors.white}`};
    padding: ${({ theme }) => theme.spacing.m};
`;

const StyledChildren = styled.div`
    ${scrollBarStyle}
    height:100%;
    overflow-y: scroll;
`;
const StyledIconButton = styled(IconButton)`
    position: absolute;
    top: 0;
    right: 0;
`;

const StyledClosingLayer = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => `${theme.colors.black}4D`};
`;

const Modal = ({ isOpen, closeHandler, children, isLarge }) => {
    useEffect(() => {
        if (!isOpen) {
            document.body.style.overflow = 'unset';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }
    return createPortal(
        <>
            <StyledClosingLayer onClick={closeHandler} />
            <StyledModalContent isLarge={isLarge}>
                <StyledIconButton onClick={closeHandler}>
                    <X size={25} />
                </StyledIconButton>
                <StyledChildren>{children}</StyledChildren>
            </StyledModalContent>
        </>,
        document.getElementById('portal')
    );
};

export default Modal;
