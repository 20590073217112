import React from 'react';
import styled, { css } from 'styled-components';

const StyledBaseTitle = css`
    color: ${({ color }) => color};
    margin-bottom: 0.5em;
    letter-spacing: 0.5px;
`;

const StyledH1Title = styled.h1`
    ${StyledBaseTitle}
    font-size: ${({ theme }) => theme.fontSize.xxl};
`;

// const StyledH2Title = styled.h2`
//     ${StyledBaseTitle}
//     font-size: ${({ theme }) => theme.fontSize.xl};
// `;

const StyledH3Title = styled.h3`
    ${StyledBaseTitle}
    font-weight: ${({ theme }) => theme.fontWeight.xl};
    font-size: ${({ theme }) => theme.fontSize.l};
`;

const StyledH4Title = styled.h4`
    ${StyledBaseTitle}
    font-weight: ${({ theme }) => theme.fontWeight.xl};
    font-size: ${({ theme }) => theme.fontSize.m};
`;

const Title = ({ component, color, children, className, style }) => {
    switch (true) {
        case component === 'h1':
            return (
                <StyledH1Title
                    className={className}
                    color={color}
                    style={style}
                >
                    {children}
                </StyledH1Title>
            );
        // case component === 'h2':
        //     return (
        //         <StyledH2Title
        //             className={className}
        //             color={color}
        //         >
        //             {children}
        //         </StyledH2Title>
        //     );
        case component === 'h3':
            return (
                <StyledH3Title
                    className={className}
                    color={color}
                    style={style}
                >
                    {children}
                </StyledH3Title>
            );
        case component === 'h4':
            return (
                <StyledH4Title
                    className={className}
                    color={color}
                    style={style}
                >
                    {children}
                </StyledH4Title>
            );

        default:
            return <div>{children}</div>;
    }
};

export default Title;
