import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import FormInputs from 'src/ui/form-ui/FormInputs';
import DropZone from 'src/ui/form-ui/DropZone';
import Title from 'src/ui/Title';
import Button from 'src/ui/Button';
import useFetch from 'src/hooks/useFetch';
import { config } from 'src/constants/endpoints';
import SpinnerScreen from 'src/ui/SpinnerScreen';

const StyledForm = styled.form`
    padding: ${({ theme }) => `${theme.spacing.s} 0`};
`;
const StyledSection = styled.section`
    margin-bottom: ${({ theme }) => theme.spacing.m};
`;
const StyledButtonWrap = styled.div`
    position: relative;
    display: flex;
    gap: ${({ theme }) => theme.spacing.xs};
`;
const StyledErrorMessage = styled.div`
    position: absolute;
    bottom: ${({ theme }) => `calc(-5px - ${theme.fontSize.xs})`};
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.colors.warning};
`;

const CourseForm = ({ isLoading, errorMessage, submitHandler, courseImage, setCourseImage, course, setCourse, isEdit }) => {
    const theme = useTheme();
    const { fetchData: scenarioFetchData, isLoading: scenarioIsLoading } = useFetch(`${config.url.SCENARIOS}`);
    const { fetchData: memberFetchData, isLoading: memberIsLoading } = useFetch(`${config.url.USERS}`);

    const [scenarioLists, setScenarioLists] = useState([]);
    const [memberLists, setMemberLists] = useState([]);

    useEffect(() => {
        // Getting scenario lists to generate checkbox for edit course page
        scenarioFetchData({}, (data) => {
            const transformed = data.results.map((item) => {
                return { ...item, id: `${item.id}-scenario`, label: item.title, value: item.id }; // id is modified because this id for generate label htmlFor
            });
            setScenarioLists(transformed);
        });
        // Getting member lists to generate checkbox for edit course page
        memberFetchData({}, (data) => {
            const transformed = data.results.map((item) => {
                return { ...item, id: `${item.id}-member`, label: item.username, value: item.id }; // id is modified because this id for generate label htmlFor
            });
            setMemberLists(transformed);
        });
    }, [memberFetchData, scenarioFetchData]);

    const COURSE_ELEMENTS = [
        {
            component: 'textarea',
            rows: 1,
            label: 'Course Name',
            id: 'name',
            property: 'name',
            editOnly: false,
        },
        {
            component: 'checkboxGroup',
            rows: 1,
            label: 'Members',
            id: 'members',
            property: 'members',
            editOnly: true,
            checkbox: memberLists,
        },
        {
            component: 'checkboxGroup',
            rows: 1,
            label: 'Assigned Scenarios',
            id: 'scenarios',
            property: 'scenarios',
            editOnly: true,
            checkbox: scenarioLists,
        },
    ];

    // Filtering edit only form inputs when it's create page
    const editElements = COURSE_ELEMENTS.filter((element) => {
        if (!isEdit && element.editOnly) {
            return null;
        }
        return element;
    });

    return (
        <StyledForm>
            <StyledSection>
                <Title
                    component="h3"
                    color={theme.colors.primary}
                >
                    Course Image
                </Title>
                <DropZone
                    setFile={setCourseImage}
                    file={courseImage}
                    imageSize={{ width: '250px', height: '180px' }}
                />
            </StyledSection>

            <StyledSection>
                <Title
                    component="h3"
                    color={theme.colors.primary}
                >
                    Course Information
                </Title>
                {scenarioIsLoading || memberIsLoading ? (
                    <SpinnerScreen />
                ) : (
                    <FormInputs
                        elements={editElements}
                        state={course}
                        setState={setCourse}
                    />
                )}
            </StyledSection>

            <StyledButtonWrap>
                {/* {!isEdit && (
                    <Button
                        variant="contained"
                        color="secondaryLight"
                        disabled={isLoading}
                    >
                        Save Draft
                    </Button>
                )} */}

                <Button
                    variant="contained"
                    color="primaryLight"
                    onClick={submitHandler}
                    disabled={isLoading}
                >
                    Save
                </Button>

                {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
            </StyledButtonWrap>
        </StyledForm>
    );
};

export default CourseForm;
