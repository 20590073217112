import React, { createContext, useState, useContext } from 'react';

const StyleContext = createContext();

// Hook
export const useStyleContext = () => {
    return useContext(StyleContext);
};

// Provider with Style info
export const StyleProvider = ({ children }) => {
    const [isSidebarMinimized, setIsSidebarMinimized] = useState(false);

    const value = {
        isSidebarMinimized,
        setIsSidebarMinimized,
    };

    return <StyleContext.Provider value={value}>{children}</StyleContext.Provider>;
};
