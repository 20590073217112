import React from 'react';
import { CaretLeftFill } from 'react-bootstrap-icons';
import styled, { useTheme } from 'styled-components';
import IconLabelLink from './IconLabelLink';

const StyledLocalNav = styled(IconLabelLink)`
    font-size: ${({ theme }) => theme.fontSize.s};
    padding-bottom: ${({ theme }) => theme.spacing.s};
    line-height: 1;
    height: 30px; // TODO: remove this once local nav is fixed
`;

const LocalNav = ({ label, to }) => {
    const theme = useTheme();
    return (
        // TODO: this should be bread crumb :
        // <StyledLocalNav
        //     to={to}
        //     color={theme.colors.black}
        // >
        //     <CaretLeftFill />
        //     {label}
        // </StyledLocalNav>
        <StyledLocalNav></StyledLocalNav>
    );
};

export default LocalNav;
