import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ArrowLeft } from 'react-bootstrap-icons';
import Title from 'src/ui/Title';
import IconLabelLink from 'src/ui/IconLabelLink';

const StyledNotFound = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const NotFoundPage = () => {
    const theme = useTheme();
    return (
        <StyledNotFound>
            <Title
                color={theme.colors.primary}
                component={'h1'}
            >
                Page Not Found
            </Title>

            <IconLabelLink to="/">
                <ArrowLeft />
                Back to Dashboard
            </IconLabelLink>
        </StyledNotFound>
    );
};

export default NotFoundPage;
