import React, { useState } from 'react';
import styled from 'styled-components';
import { SCENARIO_ELEMENTS } from 'src/constants/formElements/scenarios';
import DropZone from 'src/ui/form-ui/DropZone';
import FormInputs from 'src/ui/form-ui/FormInputs';
import Title from 'src/ui/Title';
import Button from 'src/ui/Button';
import { formValidation } from 'src/utils/formValidation';

const StyledForm = styled.form`
    padding: ${({ theme }) => `${theme.spacing.s} 0`};
`;
const StyledSection = styled.section`
    margin-bottom: ${({ theme }) => theme.spacing.m};
`;
const StyledButtonWrap = styled.div`
    position: relative;
    display: flex;
    gap: ${({ theme }) => theme.spacing.xs};
`;
const StyledErrorMessage = styled.div`
    position: absolute;
    bottom: ${({ theme }) => `calc(-5px - ${theme.fontSize.xs})`};
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.colors.warning};
`;

const ScenarioForm = ({ scenario, setScenario, submitHandler, isLoading, errorMessage }) => {
    const [scenarioImage, setScenarioImage] = useState('');
    const [isFormValidationError, setIsFormValidationError] = useState(false);

    return (
        <StyledForm>
            <StyledSection>
                <Title component="h4">Scenario Image</Title>
                <DropZone
                    setFile={setScenarioImage}
                    file={scenarioImage}
                    imageSize={{ width: '250px', height: '180px' }}
                />
            </StyledSection>

            <StyledSection>
                <Title component="h3">Scenario Information</Title>
                <FormInputs
                    elements={SCENARIO_ELEMENTS}
                    state={scenario}
                    setState={setScenario}
                />
            </StyledSection>

            <StyledButtonWrap>
                {/* <Button
            variant="contained"
            color="secondaryLight"
            disabled={isLoading || generateIsLoading}
        >
            Save Draft
        </Button> */}
                <Button
                    variant="contained"
                    color="primaryLight"
                    onClick={(e) => {
                        e.preventDefault();
                        setIsFormValidationError(false);

                        if (formValidation(SCENARIO_ELEMENTS, scenario)) {
                            submitHandler();
                        } else {
                            setIsFormValidationError(true);
                        }
                    }}
                    disabled={isLoading}
                >
                    Save
                </Button>
                {
                    isFormValidationError && <StyledErrorMessage>Please fill all required filed</StyledErrorMessage> // Error message from form validation
                }
                {
                    errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage> // Error message from API call
                }
            </StyledButtonWrap>
        </StyledForm>
    );
};

export default ScenarioForm;
