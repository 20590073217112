import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled, { useTheme } from 'styled-components';
import useAuthContext from 'src/hooks/useAuthContext';
import { BoxArrowRight } from 'react-bootstrap-icons';
import UserAvatar from 'src/ui/UserAvatar';
import { iconWrapperStyle, itemWrapperStyle, textStyle } from 'src/styles/sideBarItemStyle';

const StyledSideBarBottom = styled.div`
    border-top: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
    margin-top: auto;
    padding-top: ${({ theme }) => theme.spacing.s};
`;

const StyledItemWrapperButton = styled.button`
    ${itemWrapperStyle}
`;

const StyledItemIcon = styled.div`
    ${iconWrapperStyle}
`;

const StyledItemText = styled.div`
    ${textStyle}
    font-size: ${({ theme }) => theme.fontSize.s};
    font-weight: ${({ theme }) => theme.fontWeight.m};
`;

const StyledNameWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
    transition: gap 0.3s ease-out 0.1s;
    &.miniMized {
        gap: 0;
    }
`;

const StyledNameIcon = styled.div`
    & img {
        width: ${({ theme }) => theme.avatarSize.xs};
        height: ${({ theme }) => theme.avatarSize.xs};
        border-radius: 50%;
    }
`;
const StyledNameText = styled.div`
    flex: 1;
    font-size: ${({ theme }) => theme.fontSize.s};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.miniMized {
        opacity: 0;
        max-width: 0;
    }
`;

const SideBarBottom = ({ isMinimized }) => {
    const { currentUser } = useAuthContext();
    const theme = useTheme();
    const { logout } = useAuth0();

    const signOutHandler = () => {
        logout({
            logoutParams: {
                returnTo: `${window.location.origin}/sign-out`,
            },
        });
    };

    return (
        <StyledSideBarBottom>
            <StyledItemWrapperButton onClick={signOutHandler}>
                <StyledItemIcon className={isMinimized === true && 'miniMized'}>
                    <BoxArrowRight color={theme.colors.darkGray} />
                </StyledItemIcon>

                <StyledItemText className={isMinimized === true && 'miniMized'}>Logout</StyledItemText>
            </StyledItemWrapperButton>
            <StyledNameWrapper className={isMinimized === true && 'miniMized'}>
                <StyledNameIcon>
                    <UserAvatar />
                </StyledNameIcon>

                <StyledNameText className={isMinimized === true && 'miniMized'}>{currentUser?.nickname}</StyledNameText>
            </StyledNameWrapper>
        </StyledSideBarBottom>
    );
};

export default SideBarBottom;
