import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from 'src/constants/endpoints';
import useFetch from 'src/hooks/useFetch';
import Button from 'src/ui/Button';
import ProgressBar from 'src/ui/Charts/ProgressBar';
import Modal from 'src/ui/Modal';
import Title from 'src/ui/Title';
import styled, { useTheme } from 'styled-components';
import Table from '../../ui/Charts/Table';
import { utcToEst } from 'src/utils/formatDate';
import SpinnerIcon from 'src/ui/SpinnerIcon';
import NoResultScreen from '../../ui/NoResultScreen';

const StyledCourses = styled.div`
    display: flex;
`;

const StyledCourse = styled.div`
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ScenarioAnalytics = ({ courses }) => {
    const theme = useTheme();
    const { scenarioId } = useParams();
    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedCourseAttempts, setSelectedCourseAttempts] = useState(null);

    const { fetchData: attemptFetchData, isLoading: attemptIsLoading } = useFetch(
        `${config.url.ATTEMPTS}?scenarioId=${scenarioId}&courseId=${selectedCourse?.id}`
    );

    useEffect(() => {
        if (selectedCourse) {
            attemptFetchData({ method: 'GET' }, (data) => {
                setSelectedCourseAttempts(data);
            });
        }
    }, [attemptFetchData, selectedCourse]);

    return (
        <>
            <StyledCourses>
                {courses.map((course) => (
                    <StyledCourse key={course.id}>
                        <Title component={'h4'}>{course.name}</Title>
                        <div>Complete Rate</div>
                        <ProgressBar
                            circleSize={200}
                            percentage={50}
                            goalPercentage={50} // TODO: this is place holder number
                        />
                        <Button
                            variant="text"
                            color="black"
                            onClick={() => {
                                setSelectedCourse({ id: course.id, name: course.name });
                                setIsModalOpen(true);
                            }}
                        >
                            See Submission Lists
                        </Button>
                    </StyledCourse>
                ))}
            </StyledCourses>

            <Modal
                isOpen={isModalOpen}
                closeHandler={() => setIsModalOpen(false)}
                isLarge={true}
            >
                <Title
                    component="h4"
                    variant="h4"
                    color={theme.colors.primary}
                >
                    Attempts for {selectedCourse?.name}
                </Title>
                {attemptIsLoading ? (
                    <SpinnerIcon size={30} />
                ) : selectedCourseAttempts?.results.length > 0 ? (
                    <Table
                        columns={[
                            {
                                accessorKey: 'id',
                                header: 'ATTEMPT ID', // header property can take string or a function that returns a React component
                                size: 80,
                                cell: (props) => <div>{props.getValue()}</div>, // cell property can take a function that can return JSX. It also has access to table, row, column, cell, getValue, and renderValue as param
                            },
                            {
                                accessorKey: 'user.first_name',
                                header: 'FIRST',
                                cell: (props) => <div>{props.getValue()}</div>,
                            },
                            {
                                accessorKey: 'user.last_name',
                                header: 'LAST',
                                cell: (props) => <div>{props.getValue()}</div>,
                            },
                            {
                                accessorKey: 'updated_at',
                                header: 'SUBMISSION DATE',
                                size: 220,
                                cell: (props) => <div>{utcToEst(props.getValue())}</div>,
                            },
                            {
                                accessorKey: 'details',
                                header: 'DETAILS',
                                size: 20,
                                cell: (props) => (
                                    <button
                                        variant="text"
                                        color="black"
                                        style={{
                                            color: theme.colors.primary,
                                            fontSize: theme.fontSize.s,
                                            fontWeight: theme.fontWeight.l,
                                        }}
                                        onClick={() => {
                                            navigate(`/attempts/${props.row.original.id}`);
                                        }}
                                    >
                                        Details
                                    </button>
                                ),
                            },
                        ]}
                        data={selectedCourseAttempts?.results}
                    />
                ) : (
                    <NoResultScreen
                        largeText={'No Submission yet.'}
                        text={`There is no submission for ${selectedCourse?.name}.`}
                    />
                )}
            </Modal>
        </>
    );
};

export default ScenarioAnalytics;
