export const PATIENT_NOTE = [
    // {
    //     component: 'input',
    //     label: 'Date',
    //     id: 'date',
    //     property: 'date',
    // },
    // { component: 'h4', title: 'Personal Information' },
    {
        component: 'input',
        label: 'Patient Name',
        id: 'patientName',
        property: 'patient_name',
    },
    {
        component: 'input',
        label: 'Date of Birth',
        inputType: 'date',
        id: 'patientDob',
        property: 'patient_dob',
    },
    // {
    //     component: 'input',
    //     label: 'Age',
    //     id: 'age',
    //     property: 'age',
    // },
    // {
    //     component: 'h4',
    //     title: 'Complaint',
    // },
    {
        component: 'textarea',
        rows: 3,
        label: 'Chief Complaint',
        id: 'chiefComplaint',
        property: 'chief_complaint',
    },
    // {
    //     component: 'h4',
    //     title: 'History',
    // },
    {
        component: 'textarea',
        rows: 13,
        label: 'History of Present Illness',
        id: 'hpi',
        property: 'hpi',
    },
    // {
    //     component: 'textarea',
    //     rows: 8,
    //     label: 'Surgical History',
    //     id: 'surgicalHistory',
    //     property: 'surgicalHistory',
    // },
    {
        component: 'textarea',
        rows: 8,
        label: 'Past Medical History',
        id: 'pastMedicalHistory',
        property: 'past_medical_history',
    },

    {
        component: 'textarea',
        rows: 2,
        label: 'Allergies',
        id: 'allergies',
        property: 'allergies',
    },
    {
        component: 'textarea',
        rows: 8,
        label: 'Medications',
        id: 'medications',
        property: 'medications',
    },
    {
        component: 'textarea',
        rows: 3,
        label: 'Family History',
        id: 'familyHistory',
        property: 'family_history',
    },
    {
        component: 'textarea',
        rows: 3,
        label: 'Social History',
        id: 'socialHistory',
        property: 'social_history',
    },

    // {
    //     component: 'h4',
    //     title: 'TITLE HERE 2',
    // },
    // {
    //     component: 'textarea',
    //     rows: 22,
    //     label: 'Physical Examination',
    //     id: 'physicalExamination',
    //     property: 'physicalExamination',
    // },
    // {
    //     component: 'textarea',
    //     rows: 18,
    //     label: 'Pertinent Diagnostic Tests',
    //     id: 'pertinentDiagnosticTests',
    //     property: 'pertinentDiagnosticTests',
    // },
    // {
    //     component: 'h4',
    //     title: 'Plans',
    // },
    {
        component: 'textarea',
        rows: 15,
        label: 'Treatment Plan',
        id: 'treatmentPlan',
        property: 'treatment_plan',
    },
    // {
    //     component: 'textarea',
    //     rows: 25,
    //     label: 'Assessment and Plan',
    //     id: 'assessmentAndPlan',
    //     property: 'assessmentAndPlan',
    // },
];
