import React from 'react';
import { ChatDots, Clipboard2Plus, HeartPulse, SendFill } from 'react-bootstrap-icons';
import styled, { useTheme } from 'styled-components';
import IconButton from 'src/ui/IconButton';
import SpinnerIcon from 'src/ui/SpinnerIcon';
import Input from 'src/ui/form-ui/Input';
import Tabs from 'src/ui/Tabs';
import TabPane from 'src/ui/Tabs/TabPane';

const StyledChatFormsSection = styled.div`
    border-radius: ${({ theme }) => `${theme.borderRadius.m} ${theme.borderRadius.m} ${theme.borderRadius.m}`};
    box-shadow: ${({ theme }) => theme.shadow.s};
    padding: ${({ theme }) => theme.spacing.s};
`;

const StyledForm = styled.form`
    display: flex;
    align-items: flex-end;
    gap: ${({ theme }) => theme.spacing.s};
`;
const StyledInput = styled(Input)`
    flex: 1;
`;

const StyledIconButton = styled(IconButton)`
    background: ${({ theme }) => theme.colors.primary};
`;

const ChatFormsSection = ({ chatInputVal, setChatInputVal, physicalExamInputVal, setPhysicalExamInputVal, submitHandler, isLoading }) => {
    const theme = useTheme();

    return (
        <StyledChatFormsSection>
            {/* Chat vs PhysicalExam */}

            <Tabs preSelectedTabIndex={0}>
                <TabPane
                    title={
                        <>
                            <ChatDots /> Chat
                        </>
                    }
                >
                    <StyledForm>
                        <StyledInput
                            type="text"
                            onChange={(event) => setChatInputVal(event.target.value)}
                            value={chatInputVal}
                        />

                        <StyledIconButton
                            type="submit"
                            onClick={(event) => submitHandler(event, 'chat')}
                            color={theme.colors.primary}
                            disabled={isLoading === true || chatInputVal === ''}
                        >
                            {isLoading ? (
                                <SpinnerIcon
                                    size={20}
                                    color="currentColor"
                                />
                            ) : (
                                <SendFill
                                    color="white"
                                    size={20}
                                />
                            )}
                        </StyledIconButton>
                    </StyledForm>
                </TabPane>
                <TabPane
                    title={
                        <>
                            <HeartPulse />
                            Physical Exam
                        </>
                    }
                >
                    <StyledForm>
                        <StyledInput
                            type="text"
                            onChange={(event) => setPhysicalExamInputVal(event.target.value)}
                            value={physicalExamInputVal}
                            placeholder="Physical Exam Name"
                        />
                        <StyledIconButton
                            type="submit"
                            onClick={(event) => submitHandler(event, 'physical exam')}
                            color={theme.colors.primary}
                            disabled={isLoading === true || physicalExamInputVal === ''}
                        >
                            <Clipboard2Plus
                                color="white"
                                size={20}
                            />
                        </StyledIconButton>
                    </StyledForm>
                </TabPane>
            </Tabs>
        </StyledChatFormsSection>
    );
};

export default ChatFormsSection;
