import React from 'react';
import styled, { useTheme } from 'styled-components';
import { CheckCircleFill } from 'react-bootstrap-icons';
import Button from 'src/ui/Button';

const StyledSuccessScreen = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
`;
const StyledInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.s};
    align-items: center;
    max-width: 300px;
`;
const StyledTitle = styled.div`
    font-size: ${({ theme }) => theme.fontSize.l};
    font-weight: ${({ theme }) => theme.fontWeight.xl};
`;
const StyledMessage = styled.div`
    font-weight: ${({ theme }) => theme.fontWeight.l};
`;

const SuccessScreen = ({ title, message, url, label }) => {
    const theme = useTheme();
    return (
        <StyledSuccessScreen>
            <StyledInnerWrapper>
                <div style={{ backgroundColor: theme.colors.success, borderRadius: '50%', width: '50px', height: '50px' }}>
                    <CheckCircleFill
                        color={theme.colors.successLight}
                        size={50}
                    />
                </div>

                <StyledTitle>{title}</StyledTitle>
                <StyledMessage> {message}</StyledMessage>
                <Button
                    variant="contained"
                    color="primaryLight"
                    to={url}
                >
                    {label}
                </Button>
            </StyledInnerWrapper>
        </StyledSuccessScreen>
    );
};

export default SuccessScreen;
