/**
 * Validates form input values based on required form elements.
 *
 * @param {Array} formElements - Array of form element objects, each with `isRequired` and `property` properties.
 * @param {Object} inputValues - Object containing input values, where keys correspond to `property` of form elements.
 * @returns {boolean} - Returns `true` if all required elements are filled, `false` otherwise.
 */

export const formValidation = (formElements, inputValues) => {
    // Check if the current element is required and if the corresponding input value is empty
    for (const element of formElements) {
        if (element.isRequired && !inputValues[element.property]) {
            // Return false immediately if any required field is empty
            return false;
        }
    }

    return true;
};
