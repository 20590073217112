import React from 'react';
import styled, { keyframes } from 'styled-components';

const dotAnimation = keyframes`
   from {
    opacity:1;
  }

  to {
    opacity: 0;
  }
`;
const Dot3 = styled.circle`
    animation: ${dotAnimation} 0.9s infinite ease-in-out;
    fill: ${({ theme }) => theme.colors.gray};
`;
const Dot2 = styled.circle`
    animation: ${dotAnimation} 0.9s infinite ease-in-out 0.3s;
    fill: ${({ theme }) => theme.colors.gray};
`;
const Dot1 = styled.circle`
    animation: ${dotAnimation} 0.9s infinite ease-in-out 0.6s;
    fill: ${({ theme }) => theme.colors.gray};
`;

const ThreeDotsIcon = ({ size }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
        >
            <g transform="matrix(1,0,0,1,10.5439,-0.884463)">
                <Dot1
                    cx="2.903"
                    cy="8.884"
                    r="1.553"
                />
            </g>
            <g transform="matrix(1,0,0,1,5.09731,-0.884463)">
                <Dot2
                    cx="2.903"
                    cy="8.884"
                    r="1.553"
                />
            </g>
            <g transform="matrix(1,0,0,1,-0.349271,-0.884463)">
                <Dot3
                    cx="2.903"
                    cy="8.884"
                    r="1.553"
                />
            </g>
        </svg>
    );
};

export default ThreeDotsIcon;
