import React from 'react';
import styled, { keyframes, useTheme } from 'styled-components';

const rotate = keyframes`
   100% {
      transform: rotate(360deg);
    }
`;
const dash = keyframes`
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
`;
const StyledSpinnerIcon = styled.svg`
    animation: ${rotate} 2s linear infinite;
    & circle {
        animation: ${dash} 1.5s ease-in-out infinite;
    }
`;

const SpinnerIcon = ({ size, color }) => {
    const theme = useTheme();
    return (
        <StyledSpinnerIcon
            width={size}
            height={size}
            viewBox="0 0 50 50"
        >
            <circle
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
                color={color}
                style={{ stroke: color ? color : theme.colors.gray, strokeLinecap: 'round' }}
            ></circle>
        </StyledSpinnerIcon>
    );
};

export default SpinnerIcon;
