import React, { createContext, useState, useEffect } from 'react';
import { INSTRUCTOR_ROLE, STUDENT_ROLE } from 'src/constants/userRoles';
import { useAuth0 } from '@auth0/auth0-react';
import { PROD_URL } from 'src/constants/urls';

export const AuthContext = createContext();

// Provider with Auth info
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const { user, isLoading } = useAuth0();

    useEffect(() => {
        setCurrentUser({
            ...user,
            isInstructor: user?.[`${PROD_URL}/roles`].includes(INSTRUCTOR_ROLE) ? true : false,
            isStudent: user?.[`${PROD_URL}/roles`].includes(STUDENT_ROLE) ? true : false,
        });
    }, [user]);

    // API call to get profile
    const value = {
        currentUser,
    };

    // When it's loading, children won't be rendered
    return <AuthContext.Provider value={value}>{!isLoading && children}</AuthContext.Provider>;
};
