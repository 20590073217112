import React from 'react';
import styled from 'styled-components';

import ScenarioList from 'src/components/scenarios/ScenarioList';
const StyledScenarioLists = styled.div`
    display: flex;
    flex-direction: column;
`;

const ScenarioLists = ({ scenarios }) => {
    return (
        <StyledScenarioLists>
            {scenarios.map((scenario) => (
                <ScenarioList
                    key={scenario.id}
                    scenario={scenario}
                />
            ))}
        </StyledScenarioLists>
    );
};

export default ScenarioLists;
