// Reference:https://medium.com/fernandodof/building-a-tabs-component-with-react-596c685a9934s
import React, { useState } from 'react';
import styled from 'styled-components';
import TabTitle from './TabTitle';

const StyledTabs = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-flow: column;
`;
const StyledUl = styled.div`
    display: flex;
`;

const Tabs = ({ children, preSelectedTabIndex = 0, isFullWidth }) => {
    // First tab is shown by default unless preSelectedTabIndx assigned
    const [selectedTabIndex, setSelectedTabIndex] = useState(preSelectedTabIndex);

    return (
        <StyledTabs>
            <StyledUl>
                {children.map((item, index) => (
                    <TabTitle
                        key={index}
                        title={item.props.title}
                        index={index}
                        isActive={index === selectedTabIndex}
                        setSelectedTab={setSelectedTabIndex}
                        isFullWidth={isFullWidth}
                    />
                ))}
            </StyledUl>

            {/* show selcted tab by index*/}
            {children[selectedTabIndex]}
        </StyledTabs>
    );
};

export default Tabs;
