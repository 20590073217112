import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import scrollBarStyle from 'src/styles/scrollBarStyle';
import { ExclamationTriangle, Person, HeartPulse } from 'react-bootstrap-icons';
import ThreeDotsIcon from '../../ui/ThreeDotsIcon';
import IconButton from 'src/ui/IconButton';
import UserAvatar from 'src/ui/UserAvatar';

const StyledChantScreenSection = styled.div`
    ${scrollBarStyle}
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-right: ${({ theme }) => theme.spacing.s};
    overflow-y: scroll;
`;
const StyledInitialMessage = styled.div`
    border: ${({ theme }) => `1px solid ${theme.colors.primary}`};
    border-radius: ${({ theme }) => theme.borderRadius.s};
    background: ${({ theme }) => theme.colors.primaryLight};
    padding: ${({ theme }) => theme.spacing.s};
    color: ${({ theme }) => theme.colors.primary};
    font-weight: ${({ theme }) => theme.fontWeight.l};
    text-align: center;
`;

const StyledBaseMessageRow = styled.div`
    display: flex;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spacing.s};
    /* max-width: 60%; */
    padding-top: ${({ theme }) => theme.spacing.s};
    padding-bottom: ${({ theme }) => theme.spacing.s};
    white-space: pre-line;
`;

const StyledBotMessageRow = styled(StyledBaseMessageRow)`
    position: relative;
`;

const StyledFeedback = styled.div`
    position: absolute;
    bottom: ${({ theme }) => `-${theme.spacing.xs}`};
    right: 0;
    font-size: ${({ theme }) => theme.fontSize.xs};
`;

const StyledIconButton = styled(IconButton)`
    text-decoration: underline;
    font-size: ${({ theme }) => theme.fontSize.xs};
    &:disabled {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.gray};
    }
`;

const StyledFeedbackStatusMessage = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.xs};
`;

const StyledUserMessageRow = styled(StyledBaseMessageRow)`
    align-self: flex-end;
    flex-flow: row-reverse;
`;

const StyledBaseIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ theme }) => theme.avatarSize.s};
    height: ${({ theme }) => theme.avatarSize.s};
    padding: ${({ theme }) => theme.spacing.s};
    border-radius: 50%;
`;
const StyledBotIcon = styled(StyledBaseIcon)`
    background: ${({ theme }) => theme.colors.primaryLight};
`;
// const StyledUserIcon = styled(StyledBaseIcon)`
//     background: ${({ theme }) => theme.colors.secondaryLight};
// `;

const StyledBaseMessage = styled.div`
    position: relative;
    padding: ${({ theme }) => theme.spacing.s};
    border-radius: ${({ theme }) => theme.borderRadius.m};
    line-height: 1.5;
    &:before {
        content: '';
        position: absolute;
        top: 20px;
        margin-top: -10px;
        border: 10px solid transparent;
    }
`;
const StyledBotMessage = styled(StyledBaseMessage)`
    background: ${({ theme }) => theme.colors.primaryLight};

    &:before {
        left: -20px;
        border-right: ${({ theme }) => `${theme.colors.primaryLight} 17px solid`};
    }
`;

const StyledUserMessage = styled(StyledBaseMessage)`
    background: ${({ theme }) => theme.colors.secondaryLight};
    &:before {
        right: -20px;
        border-left: ${({ theme }) => `${theme.colors.secondaryLight} 17px solid`};
    }
`;

const StyledPhysicalExamRequestRow = styled.div`
    padding-top: ${({ theme }) => theme.spacing.s};
    padding-bottom: ${({ theme }) => theme.spacing.xxs};
    & span {
        display: inline-flex;
        align-items: center;
        gap: ${({ theme }) => theme.spacing.xxs};
        background: ${({ theme }) =>
            `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%,${theme.colors.primaryLight} 50%, ${theme.colors.primaryLight} 100%)`};
        font-size: ${({ theme }) => theme.fontSize.s};
        font-weight: ${({ theme }) => theme.fontWeight.l};
        text-transform: capitalize;
        color: ${({ theme }) => theme.colors.primary};
    }
`;
const StyledPhysicalExamResultRow = styled.div`
    padding-bottom: ${({ theme }) => theme.spacing.s};
`;

const StyledErrorMessage = styled.div`
    color: ${({ theme }) => theme.colors.warning};
`;

const ChatScreenSection = ({
    messages,
    isLoading,
    errorMessage,
    feedbackIsSuccess,
    feedbackErrorMessage,
    feedbackSubmitHandler,
    feedbackIsLoading,
    isAttemptDetailPage, // Toggle user icon or student's icon
    style,
}) => {
    const parentContainerRef = useRef(null);

    useEffect(() => {
        const wholeMessagesHeight = parentContainerRef.current.scrollHeight; // whole height including hidden part
        const scrollOffset = parentContainerRef.current.scrollTop; // how much scrolled
        const visiblePart = parentContainerRef.current.clientHeight; // visible part of the messages

        const totalNumOfMessage = parentContainerRef.current.children.length;
        const newMessage = parentContainerRef.current.children[totalNumOfMessage - 1];
        const newMessageHeight = newMessage.clientHeight;

        const topToNewMessageHeight = wholeMessagesHeight - (newMessageHeight + 110);
        const scrolledAndVisibleHeight = scrollOffset + visiblePart;

        if (topToNewMessageHeight <= scrolledAndVisibleHeight) {
            newMessage.scrollIntoView();
        }
    }, [messages]);

    return (
        <StyledChantScreenSection
            ref={parentContainerRef}
            style={style}
        >
            {messages.length <= 0 && <StyledInitialMessage>Begin your interview by typing in the input box below.</StyledInitialMessage>}
            {messages.map((message, index) => {
                return message.user === 'bot' && message.mode === 'chat' ? (
                    <StyledBotMessageRow key={index}>
                        <StyledBotIcon>
                            <Person size={50} />
                        </StyledBotIcon>
                        <div>
                            <StyledBotMessage>{message.text}</StyledBotMessage>
                        </div>
                        {!isAttemptDetailPage && (
                            <StyledFeedback>
                                {/* TODO: Add loading state */}
                                {/* Showing feedback buttons only last row */}
                                {index === messages.length - 1 && feedbackErrorMessage ? (
                                    <StyledFeedbackStatusMessage>Something went wrong. Please try again</StyledFeedbackStatusMessage>
                                ) : index === messages.length - 1 && feedbackIsSuccess ? (
                                    <StyledFeedbackStatusMessage>Thank you for submitting feedback</StyledFeedbackStatusMessage>
                                ) : (
                                    index === messages.length - 1 && (
                                        <StyledIconButton
                                            disabled={feedbackIsLoading}
                                            onClick={() => feedbackSubmitHandler()}
                                        >
                                            <ExclamationTriangle />
                                            Report Issue
                                        </StyledIconButton>
                                    )
                                )}
                            </StyledFeedback>
                        )}
                    </StyledBotMessageRow>
                ) : message.user === 'human' && message.mode === 'chat' ? (
                    <StyledUserMessageRow key={index}>
                        {/* TODO: Need to change to student user avatar */}
                        <UserAvatar
                            style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                            specificImageFor={isAttemptDetailPage ? 'students' : null}
                        />
                        <StyledUserMessage>{message.text}</StyledUserMessage>
                    </StyledUserMessageRow>
                ) : message.user === 'bot' && message.mode === 'narrator' ? (
                    <StyledPhysicalExamResultRow key={index}>{message.text}</StyledPhysicalExamResultRow>
                ) : (
                    message.user === 'human' &&
                    message.mode === 'narrator' && (
                        <StyledPhysicalExamRequestRow key={index}>
                            <span>
                                <HeartPulse /> {message.text}
                            </span>
                        </StyledPhysicalExamRequestRow>
                    )
                );
            })}

            {isLoading && (
                <StyledBotMessageRow>
                    <StyledBotIcon>
                        <Person size={50} />
                    </StyledBotIcon>
                    <div>
                        <StyledBotMessage style={{ lineHeight: 1, minWidth: '100px', textAlign: 'center' }}>
                            <ThreeDotsIcon size={30} />
                        </StyledBotMessage>
                    </div>
                </StyledBotMessageRow>
            )}
            {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
        </StyledChantScreenSection>
    );
};

export default ChatScreenSection;
