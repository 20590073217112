import React from 'react';
import styled from 'styled-components';
import SpinnerIcon from 'src/ui/SpinnerIcon';

const StyledSpinnerWrapper = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SpinnerScreen = () => {
    return (
        <StyledSpinnerWrapper>
            <SpinnerIcon size={50} />
        </StyledSpinnerWrapper>
    );
};

export default SpinnerScreen;
