// IMPORTANT!!!!
// THIS COMPONENT SHOULD BE DELETED AFTER DEMO.
// integrate this component with ChatFormsSection.jsx.

import React from 'react';

import { ChatDots, SendFill } from 'react-bootstrap-icons';
import styled, { css, useTheme } from 'styled-components';
import IconButton from 'src/ui/IconButton';
import SpinnerIcon from 'src/ui/SpinnerIcon';
import Input from 'src/ui/form-ui/Input';

const StyledChatFormsSection = styled.div`
    border-radius: ${({ theme }) => `${theme.borderRadius.m} ${theme.borderRadius.m} ${theme.borderRadius.m}`};
    box-shadow: ${({ theme }) => theme.shadow.s};
    padding: ${({ theme }) => theme.spacing.s};
`;

const StyledForm = styled.form`
    display: flex;
    align-items: flex-end;
    gap: ${({ theme }) => theme.spacing.s};
`;
const StyledInput = styled(Input)`
    flex: 1;
`;

const StyledIconButton = styled(IconButton)`
    background: ${({ theme }) => theme.colors.primary};
`;

const BaseStyle = css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme, $isActive }) => ($isActive ? theme.colors.primary : theme.colors.gray)};
    font-weight: ${({ theme, $isActive }) => ($isActive ? theme.fontWeight.l : theme.fontWeight.m)};
`;

const StyledNonFullWidthTabTitle = styled.button`
    ${BaseStyle}
    gap: ${({ theme }) => theme.spacing.xxs};
    border-bottom: ${({ theme, $isActive }) => ($isActive ? `2px solid ${theme.colors.primary}` : 'inherit')};
    padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
    font-size: ${({ theme }) => theme.fontSize.xs};
    margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

const ProtocolChatFormSection = ({ chatInputVal, setChatInputVal, physicalExamInputVal, setPhysicalExamInputVal, submitHandler, isLoading }) => {
    const theme = useTheme();

    return (
        <StyledChatFormsSection>
            <StyledNonFullWidthTabTitle $isActive={true}>
                <ChatDots /> Chat
            </StyledNonFullWidthTabTitle>
            <StyledForm>
                <StyledInput
                    type="text"
                    onChange={(event) => setChatInputVal(event.target.value)}
                    value={chatInputVal}
                />

                <StyledIconButton
                    type="submit"
                    onClick={(event) => submitHandler(event, 'chat')}
                    color={theme.colors.primary}
                    disabled={isLoading === true || chatInputVal === ''}
                >
                    {isLoading ? (
                        <SpinnerIcon
                            size={20}
                            color="currentColor"
                        />
                    ) : (
                        <SendFill
                            color="white"
                            size={20}
                        />
                    )}
                </StyledIconButton>
            </StyledForm>
        </StyledChatFormsSection>
    );
};

export default ProtocolChatFormSection;
