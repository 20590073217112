import React, { useEffect } from 'react';

import styled from 'styled-components';
import SideBar from './SideBar';

const StyledLayout = styled.div`
    display: flex;
    padding: 0 ${({ theme }) => theme.spacing.m} ${({ theme }) => theme.spacing.m};
    gap: ${({ theme }) => theme.spacing.m};
`;

const StyledMain = styled.main`
    flex: 1;
    padding-top: ${({ theme }) => theme.spacing.m};
`;

const Layout = ({ children, title }) => {
    useEffect(() => {
        document.title = `CaseLab - ${title}`;
    }, [title]);

    return (
        <StyledLayout>
            <SideBar />
            <StyledMain>{children}</StyledMain>
        </StyledLayout>
    );
};

export default Layout;
