import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { PlusCircleFill } from 'react-bootstrap-icons';
import useAuthContext from 'src/hooks/useAuthContext';
import useFetch from 'src/hooks/useFetch';
import { config } from 'src/constants/endpoints';
import Container from 'src/ui/Container';
import LocalNav from 'src/ui/LocalNav';
import SpinnerScreen from 'src/ui/SpinnerScreen';
import IconLabelLink from 'src/ui/IconLabelLink';
import ScenarioLists from 'src/components/scenarios/ScenarioLists';

const ScenarioListsPage = () => {
    const theme = useTheme();
    const { currentUser } = useAuthContext();
    const { isLoading, fetchData } = useFetch(config.url.SCENARIOS);
    const [scenarios, setScenarios] = useState([]);

    useEffect(() => {
        fetchData({}, (data) => {
            setScenarios(data.results);
        });
    }, [fetchData]);

    if (isLoading) {
        return <SpinnerScreen />;
    }

    return (
        <Container>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <LocalNav
                    label="Back to Dashboard"
                    to={'/'}
                />
                {currentUser.isInstructor && (
                    <IconLabelLink
                        to="/scenarios/create"
                        color={theme.colors.primary}
                        isBold={true}
                    >
                        <PlusCircleFill color={theme.colors.primary} /> Create New Scenario
                    </IconLabelLink>
                )}
            </div>

            <ScenarioLists scenarios={scenarios} />
        </Container>
    );
};

export default ScenarioListsPage;
