import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//import useLeavePageConfirmation from 'src/hooks/useLeavePageConfirmation';
import useFetch from 'src/hooks/useFetch';
import Container from 'src/ui/Container';
import LocalNav from 'src/ui/LocalNav';
import { config } from 'src/constants/endpoints';
import SuccessScreen from 'src/ui/SuccessScreen';
import CourseForm from 'src/components/courses/CourseForm';
import SpinnerScreen from 'src/ui/SpinnerScreen';

const CourseEditPage = () => {
    const { courseId } = useParams();
    const navigate = useNavigate();
    // useLeavePageConfirmation(); // TODO: comment in
    const { fetchData: currentCourseFetchData, isLoading: currentCourseIsLoading } = useFetch(`${config.url.COURSES}${courseId}`);
    const {
        data: submitData,
        errorMessage: submitErrorMessage,
        isLoading: submitIsLoading,
        isSuccess: submitIsSuccess,
        fetchData: submitFetchData,
    } = useFetch(`${config.url.COURSES}${courseId}/`);

    const [course, setCourse] = useState({
        name: '',
        members: [],
        scenarios: [],
    });
    const [courseImage, setCourseImage] = useState('');

    useEffect(() => {
        currentCourseFetchData(
            {},
            (data) => {
                // transform scenarios data array of object to array of integer
                // TODO: remove toString() once backend is ready
                const transformedScenarios = data.scenarios.map((scenario) => scenario.id.toString());
                // transform members data array of object to array of integer
                // TODO: remove toString() once backend is ready
                const transformedMembers = data.members.map((scenario) => scenario.id.toString());
                setCourse({ ...data, members: transformedMembers, scenarios: transformedScenarios });
            },
            (error) => {
                // Redirecting when course id is invalid
                if (error.message === '404') {
                    navigate('/not-found');
                }
            }
        );
        // Don't want to add navigate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCourseFetchData]);

    const submitHandler = async (event) => {
        event.preventDefault();
        // TODO: Transform scenarios and members property to array of integer instead of array of string. This shouldn't be used once backend changes
        const transformedCourse = () => {
            for (const key in course) {
                if (key === 'members' || key === 'scenarios') {
                    course[key] = course[key].map((item) => {
                        const num = parseInt(item, 10);
                        return num;
                    });
                }
            }
            return course;
        };

        submitFetchData({
            method: 'PATCH',
            body: JSON.stringify(transformedCourse()),
        });

        //TODO: api call for courseImage;
    };

    if (currentCourseIsLoading) {
        return <SpinnerScreen />;
    }
    return (
        <>
            {submitIsSuccess ? (
                <SuccessScreen
                    title="The Course is updated!"
                    message="View the course detail"
                    url={`/courses/${submitData.id}`}
                    label="View Course"
                />
            ) : (
                <>
                    <Container>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <LocalNav
                                label="Back to Course Lists"
                                to={'/courses'}
                            />
                        </div>

                        <CourseForm
                            isLoading={submitIsLoading}
                            errorMessage={submitErrorMessage}
                            submitHandler={submitHandler}
                            courseImage={courseImage}
                            setCourseImage={setCourseImage}
                            course={course}
                            setCourse={setCourse}
                            isEdit={true}
                        />
                    </Container>
                </>
            )}
        </>
    );
};

export default CourseEditPage;
