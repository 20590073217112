import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from 'src/constants/endpoints';
import useFetch from 'src/hooks/useFetch';
import Table from 'src/ui/Charts/Table';
import { useTheme } from 'styled-components';
import { utcToEst } from 'src/utils/formatDate';
import SpinnerIcon from 'src/ui/SpinnerIcon';
import NoResultScreen from 'src/ui/NoResultScreen';

const ScenarioStudentAttemptLists = () => {
    const { scenarioId } = useParams();
    const theme = useTheme();
    const navigate = useNavigate();
    const [attemptLists, setAttemptLists] = useState(null);

    const { fetchData: attemptFetchData, isLoading: attemptIsLoading } = useFetch(`${config.url.ATTEMPTS}?scenarioId=${scenarioId}`);

    useEffect(() => {
        attemptFetchData({ method: 'GET' }, (data) => {
            setAttemptLists(data);
        });
    }, [attemptFetchData]);

    return attemptIsLoading ? (
        <SpinnerIcon size={30} />
    ) : attemptLists?.results.length > 0 ? (
        <Table
            columns={[
                {
                    accessorKey: 'id',
                    header: 'ATTEMPT ID', // header property can take string or a function that returns a React component
                    size: 80,
                    cell: (props) => <div>{props.getValue()}</div>, // cell property can take a function that can return JSX. It also has access to table, row, column, cell, getValue, and renderValue as param
                },
                {
                    accessorKey: 'updated_at',
                    header: 'SUBMISSION DATE',
                    size: 220,
                    cell: (props) => <div>{utcToEst(props.getValue())}</div>,
                },
                {
                    accessorKey: 'details',
                    header: 'DETAILS',
                    size: 20,
                    cell: (props) => (
                        <button
                            variant="text"
                            color="black"
                            style={{
                                color: theme.colors.primary,
                                fontSize: theme.fontSize.s,
                                fontWeight: theme.fontWeight.l,
                            }}
                            onClick={() => {
                                navigate(`/attempts/${props.row.original.id}`);
                            }}
                        >
                            Details
                        </button>
                    ),
                },
            ]}
            data={attemptLists?.results}
        />
    ) : (
        <NoResultScreen
            largeText={'No Submission yet.'}
            text={`Please start from "Start Assessment" button on the top right`}
        />
    );
};
// return <pre>{JSON.stringify(attemptLists.results, null, 2)}</pre>;

export default ScenarioStudentAttemptLists;
