import React from 'react';
import styled from 'styled-components';
import Button from 'src/ui/Button';

const StyledNoteButtons = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: ${({ theme }) => theme.spacing.xs};
    gap: ${({ theme }) => theme.spacing.xs};
`;

const NoteButtons = ({ submitHandler }) => {
    return (
        <StyledNoteButtons>
            {/* TODO: TBI draft */}
            {/* <Button
                color="secondaryLight"
                variant="contained"
                type="submit"
                onClick={(event) => {
                    event.preventDefault();
                    submitHandler(true);
                }}
            >
                Save Draft
            </Button> */}
            <Button
                color="primaryLight"
                variant="contained"
                type="submit"
                onClick={(event) => {
                    event.preventDefault();
                    submitHandler(false);
                }}
            >
                Submit Note and Conversation
            </Button>
            <Button
                color="primary"
                variant="outlined"
                onClick={(event) => {}}
            >
                Cancel
            </Button>
        </StyledNoteButtons>
    );
};
export default NoteButtons;
