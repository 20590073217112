import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
//import useLeavePageConfirmation from 'src/hooks/useLeavePageConfirmation';
import useFetch from 'src/hooks/useFetch';
import useAuthContext from 'src/hooks/useAuthContext';

import Button from 'src/ui/Button';
import Container from 'src/ui/Container';

import LocalNav from 'src/ui/LocalNav';
import Title from 'src/ui/Title';
import { config } from 'src/constants/endpoints';
import SuccessScreen from 'src/ui/SuccessScreen';
import { ArrowClockwise } from 'react-bootstrap-icons';
import Modal from 'src/ui/Modal';

import SpinnerScreen from 'src/ui/SpinnerScreen';
import ScenarioForm from 'src/components/scenarios/ScenarioForm';

const ScenarioCreatePage = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    // useLeavePageConfirmation(); // TODO: comment in
    const { currentUser } = useAuthContext();
    const { errorMessage, isLoading, isSuccess, fetchData } = useFetch(config.url.SCENARIOS);
    const { isLoading: generateIsLoading, fetchData: generateFetchData } = useFetch(config.url.GENERATE);

    const [scenario, setScenario] = useState({});

    const [isModalOpen, setIsModalOpen] = useState(false);

    const submitHandler = async () => {
        fetchData({
            method: 'POST',
            body: JSON.stringify(scenario),
        });

        //TODO: api call for scenarioImage;
    };

    const autoGenerateSubmitHandler = async () => {
        generateFetchData({ method: 'POST' }, (data) => {
            setScenario((prev) => {
                return { prev, ...data };
            });
            setIsModalOpen(false);
        });
    };

    if (!currentUser.isInstructor) {
        return navigate('/');
    }

    if (isLoading || generateIsLoading) {
        return <SpinnerScreen />;
    }

    return (
        <>
            {isSuccess ? (
                <SuccessScreen
                    title="Success!"
                    message="New Scenario is created!"
                    url="/scenarios/"
                    label="Back to Scenarios"
                />
            ) : (
                <>
                    <Container>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <LocalNav
                                label="Back to Scenario Lists"
                                to={'/scenarios'}
                            />

                            <Button
                                onClick={() => setIsModalOpen(true)}
                                variant="contained"
                                color="primaryLight"
                                disabled={generateIsLoading || isLoading}
                            >
                                <ArrowClockwise />
                                Generate A Scenario
                            </Button>
                        </div>

                        <ScenarioForm
                            scenario={scenario}
                            setScenario={setScenario}
                            submitHandler={submitHandler}
                            isLoading={isLoading || generateIsLoading}
                            errorMessage={errorMessage}
                        />
                    </Container>
                    <Modal
                        isOpen={isModalOpen}
                        closeHandler={() => setIsModalOpen(false)}
                    >
                        <div style={{ textAlign: 'center', paddingTop: theme.spacing.s }}>
                            <Title component="h3">Do you want to auto generate a scenario?</Title>
                            <div style={{ display: 'flex', gap: theme.spacing.s, justifyContent: 'center', marginTop: theme.spacing.m }}>
                                <Button
                                    onClick={autoGenerateSubmitHandler}
                                    variant="contained"
                                    color="primaryLight"
                                >
                                    Yes, auto generate a scenario
                                </Button>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </>
            )}
        </>
    );
};

export default ScenarioCreatePage;
