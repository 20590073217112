import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import theme from 'src/styles/theme';
import { ThemeProvider } from 'styled-components';
import App from 'src/App';
import GlobalStyle from 'src/styles/globalStyle';
import Auth0ProviderWithNavigate from './lib/Auth0ProviderWithNavigate';
import { StyleProvider } from './contexts/StyleContext';
import { AuthProvider } from 'src/contexts/AuthContext';
import { ConfirmationProvider } from 'src/contexts/ConfirmationContext';
import * as Sentry from '@sentry/react';
import { LOCAL_URL } from './constants/urls';

Sentry.init({
    dsn: 'https://df2f0796f372d80091a5b1daa2e9b00b@o4507217256644608.ingest.us.sentry.io/4507244193906688',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: window.origin === LOCAL_URL ? 'local' : 'development',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Auth0ProviderWithNavigate>
                    <AuthProvider>
                        <StyleProvider>
                            <ConfirmationProvider>
                                <GlobalStyle />
                                <App />
                            </ConfirmationProvider>
                        </StyleProvider>
                    </AuthProvider>
                </Auth0ProviderWithNavigate>
            </BrowserRouter>
        </ThemeProvider>
    </React.StrictMode>
);
