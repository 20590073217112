import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.label`
    position: relative;
    color: ${({ theme }) => theme.colors.darkGray};
    font-size: ${({ theme }) => theme.fontSize.s};
    font-weight: ${({ theme }) => theme.fontWeight.xl};
    padding-left: ${({ theme }) => theme.spacing.xs};
    color: ${({ theme }) => theme.colors.darkGray};
    &:after {
        content: ${({ isRequired }) => (isRequired ? '"*"' : null)};
        position: absolute;
        top: -5px;
        color: ${({ theme }) => theme.colors.warning};
        font-size: 25px;
    }
`;
export const Label = (props) => {
    return <StyledLabel {...props}>{props.children}</StyledLabel>;
};
