import React from 'react';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
// import { Book } from 'react-bootstrap-icons';
import Title from 'src/ui/Title';
import ProgressBar from 'src/ui/Charts/ProgressBar';
import useAuthContext from 'src/hooks/useAuthContext';
import placeholderImageCourses from 'src/assets/img/placeholderImageCourses.jpg';

const StyledCourseList = styled(Link)`
    display: flex;
    gap: ${({ theme }) => theme.spacing.m};
    padding-top: ${({ theme }) => theme.spacing.m};
    padding-bottom: ${({ theme }) => theme.spacing.m};
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
`;
const StyledImageWrapper = styled.div`
    width: ${({ theme }) => theme.imageSize.m.width};
    height: ${({ theme }) => theme.imageSize.m.height};
`;

const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.m};
    object-fit: cover;
`;

// const StyledIconWrapper = styled.div`
//     border-radius: ${({ theme }) => theme.borderRadius.m};
//     border: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     height: 100%;
// `;
const StyledContentWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const StyledDescription = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.m};
    display: flex;
    flex-flow: column;
    gap: ${({ theme }) => theme.spacing.xs};
`;

const CourseList = ({ course }) => {
    const theme = useTheme();
    const { currentUser } = useAuthContext();

    return (
        <StyledCourseList to={`/courses/${course.id}`}>
            <StyledImageWrapper>
                {/* TODO: add image
                {course.image ? (*/}
                <StyledImage
                    src={placeholderImageCourses}
                    alt=""
                />
                {/*}) : ( */}

                {/* <StyledIconWrapper>
                    <Book
                        size="50"
                        color={theme.colors.gray}
                    />
                </StyledIconWrapper> */}
                {/* )} */}
            </StyledImageWrapper>
            <StyledContentWrapper>
                <Title
                    color={theme.colors.primary}
                    component="h3"
                >
                    {course.name}
                </Title>
                <StyledDescription>
                    <div>Semester: Fall, 2024</div> {/* TODO: palace holder */}
                    <div>Number of Scenario: 3</div> {/* TODO: palace holder */}
                    {/* TODO: ensure instructor is not included */}
                    {currentUser.isInstructor && <div>Number of Students: {course.members.length}</div>}
                </StyledDescription>
            </StyledContentWrapper>

            <div style={{ textAlign: 'center' }}>
                {currentUser.isInstructor ? (
                    <>
                        <Title
                            color={theme.colors.darkGray}
                            component="h4"
                        >
                            Submission Rate
                        </Title>
                        <ProgressBar
                            circleSize={130}
                            percentage={60}
                            goalPercentage={50} // TODO: this is place holder number
                        />
                    </>
                ) : (
                    currentUser.isStudent && (
                        <>
                            <Title
                                color={theme.colors.darkGray}
                                component="h4"
                            >
                                Submission Rate
                            </Title>
                            <ProgressBar
                                circleSize={130}
                                percentage={60}
                                goalPercentage={50} // TODO: this is place holder number
                            />
                        </>
                    )
                )}
            </div>
        </StyledCourseList>
    );
};

export default CourseList;
