import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledIconLabelLink = styled(Link)`
    display: inline-flex;
    gap: ${({ theme }) => theme.spacing.xs};
    align-items: center;
    color: ${({ color }) => (color ? color : 'inherit')};
    font-weight: ${({ bold, theme }) => (bold === 'true' ? theme.fontWeight.l : 'inherit')};

    &:hover {
        text-decoration: underline;
    }
`;

const IconLabelLink = ({ className, children, to, color, isBold = false }) => {
    return (
        <StyledIconLabelLink
            className={className}
            to={to}
            color={color}
            bold={isBold ? 'true' : 'false'}
        >
            {children}
        </StyledIconLabelLink>
    );
};

export default IconLabelLink;
