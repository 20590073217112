// TODO: THIS IS ONLY QA purpose. Remove this function completely before beta launch

import React from 'react';
import placeholderStudent from 'src/assets/img/placeholderStudent.jpg';
import placeholderInstructor from 'src/assets/img/placeholderInstructor.jpg';
import useAuthContext from 'src/hooks/useAuthContext';
// For QA
const UserAvatar = (props) => {
    const { currentUser } = useAuthContext();

    // TODO: This need to be another users avatar
    if (props.specificImageFor === 'students') {
        return (
            <img
                style={props.style}
                src={placeholderStudent}
                alt=""
            />
        );
    }

    // TODO: This need to be another users avatar
    if (props.specificImageFor === 'instructors') {
        return (
            <img
                style={props.style}
                src={placeholderInstructor}
                alt=""
            />
        );
    }

    return (
        <img
            style={props.style}
            src={currentUser.picture}
            alt=""
        />
    );
};

export default UserAvatar;
