// globalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        padding: 0;
        margin: 0;
    }

    html,
    body {
        color: ${({ theme }) => theme.colors.black};
        font-family:${({ theme }) => theme.fontFamilies.primary};
        font-weight:  ${({ theme }) => theme.fontWeight.m};
        font-size: ${({ theme }) => theme.fontSize.m};
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    img {
        max-width: 100%;
        height: auto;
    }
    
    /* WYSIWHG editor */
    p {
        line-height: 1.5;
        margin-bottom: 1em;
    }
    /* ul, ol{
        margin-bottom: .5em;
    } */
    li {
        margin-left: 1em;
        /* margin-bottom: .5em; */
        line-height: 1.3;
    }
    button {
        font-family:inherit;
        background-color: transparent;
        border:none;
        color: inherit;
        cursor: pointer;
    }
  
`;

export default GlobalStyle;
