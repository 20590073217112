import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
// import { PlusCircleFill } from 'react-bootstrap-icons';
import { Pencil } from 'react-bootstrap-icons';
import useAuthContext from 'src/hooks/useAuthContext';
import useFetch from 'src/hooks/useFetch';
import { config } from 'src/constants/endpoints';
import Container from 'src/ui/Container';
import LocalNav from 'src/ui/LocalNav';
import SpinnerScreen from 'src/ui/SpinnerScreen';
import IconLabelLink from 'src/ui/IconLabelLink';
import { useNavigate, useParams } from 'react-router-dom';
import Title from 'src/ui/Title';
import ScenarioLists from 'src/components/scenarios/ScenarioLists';
import placeholderImage from 'src/assets/img/placeholderImageCourses.jpg';
import NoResultScreen from 'src/ui/NoResultScreen';

const StyledTitleRow = styled.div`
    display: flex;
    justify-content: space-between;
`;
const StyledScenarioTitle = styled(Title)`
    max-width: ${({ theme }) => theme.breakPoints.s};
`;

const StyledButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.s};
`;
const StyedTopRow = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.l};
    margin-bottom: ${({ theme }) => theme.spacing.l};
`;

const StyledImageWrapper = styled.div`
    width: ${({ theme }) => theme.imageSize.l.width};
    height: ${({ theme }) => theme.imageSize.l.height};
`;
const StyledImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.m};
    object-fit: cover;
`;

const StyledScenarioListsWrapper = styled.div`
    padding-top: ${({ theme }) => theme.spacing.m};
    border-top: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
`;

const StyledTitle = styled(Title)`
    text-align: center;
    margin-bottom: 0;
`;

const CourseDetailPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { courseId } = useParams();
    const { currentUser } = useAuthContext();
    const { isLoading: scenarioListsIsLoading, fetchData: scenarioListsFetchData } = useFetch(`${config.url.SCENARIOS}?courseId=${courseId}`);
    const { isLoading: courseDetailIsLoading, fetchData: courseDetailFetchData } = useFetch(`${config.url.COURSES}${courseId}`);
    const [scenarios, setScenarios] = useState([]);
    const [courseDetail, setCourseDetail] = useState([]);

    useEffect(() => {
        scenarioListsFetchData({}, (data) => {
            setScenarios(data.results);
        });
    }, [scenarioListsFetchData]);

    useEffect(() => {
        courseDetailFetchData(
            {},
            (data) => {
                setCourseDetail(data);
            },
            (error) => {
                // Redirecting when course id is invalid
                if (error.message === '404') {
                    navigate('/not-found');
                }
            }
        );
        // Don't want to add navigate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseDetailFetchData]);

    if (scenarioListsIsLoading || courseDetailIsLoading) {
        return <SpinnerScreen />;
    }

    return (
        <Container>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <LocalNav
                    label="Back to Course Lists"
                    to={'/courses'}
                />
            </div>

            <StyledTitleRow>
                <StyledScenarioTitle
                    component="h1"
                    color={theme.colors.primary}
                >
                    {courseDetail.name}
                </StyledScenarioTitle>
                <StyledButtonWrapper>
                    {currentUser.isInstructor && (
                        <IconLabelLink to={`/courses/${courseDetail.id}/edit`}>
                            <Pencil />
                            Edit
                        </IconLabelLink>
                    )}
                </StyledButtonWrapper>
            </StyledTitleRow>
            <StyedTopRow>
                <StyledImageWrapper>
                    <StyledImage
                        src={placeholderImage}
                        alt=""
                    />
                </StyledImageWrapper>
                <div>
                    <div>Number of Members: {courseDetail?.members?.length}</div> {/* TODO: Show lists of members*/}
                    <div>Semester: Fall, 2024</div> {/* TODO: Placeholder*/}
                </div>
            </StyedTopRow>

            <StyledScenarioListsWrapper>
                <StyledTitle
                    component="h3"
                    color={theme.colors.primary}
                >
                    Assigned Scenarios
                </StyledTitle>
                {/* {currentUser.isInstructor && (
                    <div style={{ textAlign: 'right' }}>
                        <IconLabelLink
                            to="/#"
                            color={theme.colors.primary}
                            isBold={true}
                        >
                            <PlusCircleFill color={theme.colors.primary} />
                            Add More Scenarios
                        </IconLabelLink>
                    </div>
                )} */}
                {scenarios.length > 0 ? (
                    <ScenarioLists scenarios={scenarios} />
                ) : (
                    <NoResultScreen
                        largeText={'No Scenario is assigned yet.'}
                        text={currentUser.isInstructor && `Please assign a scenario from "Edit" button on the top right`}
                    />
                )}
            </StyledScenarioListsWrapper>
        </Container>
    );
};

export default CourseDetailPage;
