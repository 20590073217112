import React from 'react';
import { Search } from 'react-bootstrap-icons';

import styled, { useTheme } from 'styled-components';

const StyledNoResultScreen = styled.div`
    padding: ${({ theme }) => theme.spacing.xl};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.m};
`;
const StyledLargeText = styled.div`
    font-size: ${({ theme }) => theme.fontSize.xl};
    font-weight: ${({ theme }) => theme.fontWeight.xl};
    color: ${({ theme }) => theme.colors.dargGray};
`;
const StyledText = styled.div``;

const NoResultScreen = ({ largeText, text, className, style }) => {
    const theme = useTheme();
    return (
        <StyledNoResultScreen
            className={className}
            style={style}
        >
            <Search
                size={60}
                color={theme.colors.gray}
            />
            <StyledLargeText>{largeText}</StyledLargeText>
            <StyledText>{text}</StyledText>
        </StyledNoResultScreen>
    );
};

export default NoResultScreen;
