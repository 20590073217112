import React from 'react';
import styled, { css } from 'styled-components';

const BaseStyle = css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme, $isActive }) => ($isActive ? theme.colors.primary : theme.colors.gray)};
    font-weight: ${({ theme, $isActive }) => ($isActive ? theme.fontWeight.l : theme.fontWeight.m)};
`;

const StyledFullWidthTabTitle = styled.button`
    ${BaseStyle}
    flex: 1;
    gap: ${({ theme }) => theme.spacing.xs};
    border-radius: ${({ theme }) => `${theme.borderRadius.s} ${theme.borderRadius.s} 0 0 `};
    background: ${({ theme, $isActive }) => ($isActive ? theme.colors.primaryLight2 : 'transparent')};
    border-bottom: ${({ theme, $isActive }) => ($isActive ? `2px solid ${theme.colors.primary}` : `1px solid ${theme.colors.gray}`)};
    padding: ${({ theme }) => theme.spacing.xs};
    font-size: ${({ theme }) => theme.fontSize.s};
`;

const StyledNonFullWidthTabTitle = styled.button`
    ${BaseStyle}
    gap: ${({ theme }) => theme.spacing.xxs};
    border-bottom: ${({ theme, $isActive }) => ($isActive ? `2px solid ${theme.colors.primary}` : 'inherit')};
    padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
    font-size: ${({ theme }) => theme.fontSize.xs};
`;

const TabTitle = ({ title, setSelectedTab, index, isActive, isFullWidth }) => {
    const handleOnClick = () => {
        setSelectedTab(index);
    };

    if (isFullWidth) {
        return (
            <StyledFullWidthTabTitle
                $isFullWidth={isFullWidth}
                onClick={handleOnClick}
                $isActive={isActive}
            >
                {title}
            </StyledFullWidthTabTitle>
        );
    } else {
        return (
            <StyledNonFullWidthTabTitle
                $isFullWidth={isFullWidth}
                onClick={handleOnClick}
                $isActive={isActive}
            >
                {title}
            </StyledNonFullWidthTabTitle>
        );
    }
};

export default TabTitle;
