import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthContext from 'src/hooks/useAuthContext';
// import { useConfirmationContext } from 'src/contexts/ConfirmationContext';
import Container from 'src/ui/Container';
import ScenarioForm from 'src/components/scenarios/ScenarioForm';
import SuccessScreen from 'src/ui/SuccessScreen';
import LocalNav from 'src/ui/LocalNav';
import SpinnerScreen from 'src/ui/SpinnerScreen';
import useFetch from 'src/hooks/useFetch';
import { config } from 'src/constants/endpoints';

const ScenarioEditPage = () => {
    const { scenarioId } = useParams();
    const navigate = useNavigate();
    const { currentUser } = useAuthContext();

    const { fetchData: currentScenarioFetchData, isLoading: currentScenarioIsLoading } = useFetch(`${config.url.SCENARIOS}${scenarioId}`);
    const { fetchData: submitFetchData, errorMessage, isLoading, isSuccess } = useFetch(`${config.url.SCENARIOS}${scenarioId}/`);

    const [scenario, setScenario] = useState({});

    useEffect(() => {
        currentScenarioFetchData(
            {},
            (data) => {
                setScenario(data);
            },
            (error) => {
                // Redirecting when scenario id is invalid
                if (error.message === '404') {
                    navigate('/not-found');
                }
            }
        );
        // Don't want to add navigate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentScenarioFetchData]);

    const submitHandler = async () => {
        submitFetchData({
            method: 'PATCH',
            body: JSON.stringify(scenario),
        });

        //TODO: api call for scenarioImage;
    };

    if (!currentUser.isInstructor) {
        navigate('/');
    }
    if (currentScenarioIsLoading || isLoading) {
        return <SpinnerScreen />;
    }

    return (
        <>
            {isSuccess ? (
                <SuccessScreen
                    title="Success!"
                    message="Scenario is updated!"
                    url={`/scenarios/${scenarioId}`}
                    label="Back to the Scenario"
                />
            ) : (
                <>
                    <Container>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                            <LocalNav
                                label="Back to Scenario Lists"
                                to={'/scenarios'}
                            />
                        </div>

                        <ScenarioForm
                            scenario={scenario}
                            setScenario={setScenario}
                            submitHandler={submitHandler}
                            isLoading={isLoading}
                            errorMessage={errorMessage}
                        />
                    </Container>
                </>
            )}
        </>
    );
};

export default ScenarioEditPage;
