import React from 'react';
import styled from 'styled-components';
import { Label } from './Label';

const StyledInputWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
`;
const StyledInput = styled.input`
    padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.s};
    border: ${({ theme }) => `${theme.colors.gray} 1px solid`};
    outline: none;
    border-radius: ${({ theme }) => theme.borderRadius.l};
    font: inherit;
`;

const Input = (props) => {
    return (
        <StyledInputWrapper className={props.className}>
            {props.label && (
                <Label
                    htmlFor={props.id}
                    isRequired={props.isRequired}
                >
                    {props.label}
                </Label>
            )}
            <StyledInput {...props} />
        </StyledInputWrapper>
    );
};

export default Input;
