import React, { useState } from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';

import ChatScreenSection from 'src/components/attempts/ChatScreenSection';
import ProtocolChatFormSection from 'src/components/protocol/ProtocolChatFormSection';
import { config } from 'src/constants/endpoints';

import useFetch from 'src/hooks/useFetch';
import Title from 'src/ui/Title';
import styled, { useTheme } from 'styled-components';

const StyledProtocolPage = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.xs};
`;

const StyledLeft = styled.div`
    display: flex;
    flex-direction: column;
    /* 32px = 14px + padding  + 2px of LocalNav. Use useRef() if needed */
    height: ${({ theme }) => `calc(100vh - (${theme.spacing.m})*2)`};
    width: 50%;
`;
const StyledRight = styled.div`
    width: 50%;
    padding-left: ${({ theme }) => theme.spacing.s};
    height: ${({ theme }) => `calc(100vh - (${theme.spacing.m})*2 - 32px)`};
    overflow-y: auto;
`;
const StyledCompleteLists = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledCompleteList = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.xs};
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
    padding: ${({ theme }) => theme.spacing.s};
    border-radius: ${({ theme }) => theme.borderRadius.s};
`;

const StyledLine = styled.div`
    align-self: center;
    width: 240px;
    height: 4px;
    background-color: ${({ theme }) => theme.colors.lightGray};
`;

const StyledCompleteText = styled.span`
    padding-top: ${({ theme }) => theme.spacing.xxs};
`;

const ProtocolPage = () => {
    const theme = useTheme();
    const { data: chatData, errorMessage: chatErrorMessage, isLoading: chatIsLoading, fetchData: chatFetchData } = useFetch(config.url.PROTOCOL_CHAT);
    // TODO: add report issue functionality
    const [messages, setMessages] = useState([
        {
            user: 'bot',
            text: "Hello. We'll be working through a chest pain scenario today. You'll lead the scenario and I will guide you if you run into difficulties.You approach a male patient sitting on the floor in an office setting. Several coworkers are gathered around.",
            mode: 'chat',
        },
    ]);

    const [chatInputVal, setChatInputVal] = useState('');

    const messageSubmitHandler = async (event, category) => {
        event.preventDefault();

        // TODO: refactor this
        let reqBody = '';
        if (category === 'chat') {
            // set users message into messages state
            setMessages((prev) => {
                return [...prev, { user: 'human', text: chatInputVal, mode: 'chat' }];
            });
            reqBody = JSON.stringify({ message: chatInputVal, history: messages, mode: 'chat', scenario_id: 1 }); // mode: 'narrator'
        }

        chatFetchData(
            {
                method: 'POST',
                body: reqBody,
            },
            (data) => {
                const clientRes = {
                    user: 'bot',
                    text: data.text,
                    mode: data.mode,
                };
                // set bot message into messages state
                setMessages((prev) => {
                    return [...prev, clientRes];
                });
            }
        );

        setChatInputVal('');
    };

    return (
        <StyledProtocolPage>
            <StyledLeft>
                <ChatScreenSection
                    messages={messages}
                    isLoading={chatIsLoading}
                    errorMessage={chatErrorMessage}
                    feedbackIsSuccess={false}
                    feedbackErrorMessage={''}
                    feedbackSubmitHandler={() => console.log('submitted')}
                    feedbackIsLoading={false}
                />

                <ProtocolChatFormSection
                    chatInputVal={chatInputVal}
                    setChatInputVal={setChatInputVal}
                    physicalExamInputVal={''}
                    setPhysicalExamInputVal={() => console.log('physical exam')}
                    submitHandler={messageSubmitHandler}
                    isLoading={chatIsLoading}
                />
            </StyledLeft>
            <StyledRight>
                <Title
                    component="h3"
                    color={theme.colors.primary}
                >
                    Completed Protocol Steps
                </Title>
                <StyledCompleteLists>
                    {!chatData.protocol_steps_completed || chatData.protocol_steps_completed.length === 0 ? (
                        <StyledCompleteList>
                            <CheckCircleFill
                                color={theme.colors.lightGray}
                                size={25}
                            />
                            <StyledLine />
                        </StyledCompleteList>
                    ) : (
                        chatData.protocol_steps_completed?.map((step) => (
                            <StyledCompleteList>
                                <CheckCircleFill
                                    color={theme.colors.success}
                                    size={25}
                                />
                                <StyledCompleteText>{step}</StyledCompleteText>
                            </StyledCompleteList>
                        ))
                    )}
                </StyledCompleteLists>
            </StyledRight>
        </StyledProtocolPage>
    );
};

export default ProtocolPage;
