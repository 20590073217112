import React from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
    margin: 0 auto;
    max-width: 1170px;
`;

const Container = ({ children, className, style }) => {
    return (
        <StyledContainer
            className={className}
            style={style}
        >
            {children}
        </StyledContainer>
    );
};

export default Container;
