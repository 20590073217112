import React from 'react';
import styled from 'styled-components';

const StyledCheckBox = styled.div`
    display: flex;

    gap: ${({ theme }) => theme.spacing.xs};
`;
const StyledInput = styled.input``;

const CheckBox = ({ id, label, value, onChangeHandler, isChecked }) => {
    return (
        <StyledCheckBox>
            <StyledInput
                type="checkbox"
                id={id}
                value={value}
                onChange={(event) => onChangeHandler(event)}
                checked={isChecked}
            />
            <label htmlFor={id}>{label}</label>
        </StyledCheckBox>
    );
};

export default CheckBox;
