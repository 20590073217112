import React from 'react';
import scrollBarStyle from 'src/styles/scrollBarStyle';
import styled from 'styled-components';

const StyledTabPane = styled.div`
    ${scrollBarStyle}
    flex: 1;
    padding-top: ${({ theme }) => theme.spacing.xs};
    overflow-y: scroll;
`;

const TabPane = ({ children }) => {
    return <StyledTabPane>{children}</StyledTabPane>;
};

export default TabPane;
