import React, { useEffect, useRef, useState } from 'react';

import styled, { useTheme } from 'styled-components';
import Title from '../Title';
import { CaretDownFill, CaretUpFill } from 'react-bootstrap-icons';

const StyledAccordionItem = styled.div`
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.lightGray}`};
    padding: ${({ theme }) => theme.spacing.s};
    transition: opacity 0.1s ease-in-out;
`;
const StyledAccordionTitle = styled.button`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;
    & > h4 {
        margin-bottom: 0;
    }
`;
const StyledAccordionDetail = styled.div`
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    &.open {
        padding-top: ${({ theme }) => theme.spacing.s};
        opacity: 1;
    }
`;

const AccordionItem = ({ content }) => {
    const theme = useTheme();
    const detailRef = useRef();
    const [isOpen, setIsOpen] = useState(true); // This need to true initially to setDetailHeight() in useEffect()
    const [detailHeight, setDetailHeight] = useState(null);
    const [isReady, setIsReady] = useState(false); // isReady value is used to control whole component opacity and transition until the accordion is closed on initial load.

    useEffect(() => {
        // set Detail Height
        if (!isReady && detailRef.current) {
            setDetailHeight(detailRef.current.clientHeight);
            setIsOpen(false);
            // This needs to have setTimeout to make sure to turn off transition until the accordions are closed
            setTimeout(() => {
                setIsReady(true);
            }, 300); // 300ms come from transition on Accordion detail
        }
    }, [isReady]);

    return (
        <StyledAccordionItem style={{ opacity: !isReady && 0 }}>
            <StyledAccordionTitle onClick={() => setIsOpen((prev) => !prev)}>
                <Title component="h4">{content.title}</Title>
                {isOpen ? <CaretUpFill color={theme.colors.primary} /> : <CaretDownFill color={theme.colors.primary} />}
            </StyledAccordionTitle>
            <StyledAccordionDetail
                ref={detailRef}
                className={isOpen && 'open'}
                style={{
                    maxHeight: isOpen ? detailHeight : 0,
                    transition: !isReady && 'none',
                }}
                dangerouslySetInnerHTML={{ __html: content.detail }}
            />
        </StyledAccordionItem>
    );
};

export default AccordionItem;
