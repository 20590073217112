// Converting utc to EST time zone and remove time
export const utcToEstNoTime = (time) => {
    const date = new Date(time);
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        timeZone: 'America/Toronto', // Set the time zone to EST. Adjust this when time zone setting is added later
    };
    // Convert to a nicer format in EST time zone
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
};

// Converting utc to EST time zone
export const utcToEst = (time) => {
    const date = new Date(time);
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
        timeZone: 'America/Toronto', // Set the time zone to EST. Adjust this when time zone setting is added later
    };
    // Convert to a nicer format in EST time zone
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
};
