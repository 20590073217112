import React from 'react';
import styled from 'styled-components';

const StyledIconButton = styled.button`
    display: inline-flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
    background-color: ${(props) => props.color ?? 'transparent'};
    border: none;
    border-radius: ${({ theme }) => theme.borderRadius.s};
    padding: ${({ theme }) => theme.spacing.xs};
    font-family: ${({ theme }) => theme.fontFamilies.primary};
    font-weight: ${({ theme }) => theme.fontWeight.m};
    cursor: pointer;
    &:disabled {
        background: ${({ theme }) => theme.colors.gray};
        cursor: initial;
    }
`;

const IconButton = (props) => {
    return (
        <StyledIconButton
            {...props}
            className={props.className}
        >
            {props.children}
        </StyledIconButton>
    );
};

export default IconButton;
