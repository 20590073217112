import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
//import useLeavePageConfirmation from 'src/hooks/useLeavePageConfirmation';
import useFetch from 'src/hooks/useFetch';

import Button from 'src/ui/Button';
import Container from 'src/ui/Container';

import LocalNav from 'src/ui/LocalNav';
import Title from 'src/ui/Title';
import { config } from 'src/constants/endpoints';
import { ArrowClockwise } from 'react-bootstrap-icons';
import Modal from 'src/ui/Modal';

import SpinnerScreen from 'src/ui/SpinnerScreen';
import DropZone from 'src/ui/form-ui/DropZone';
import Textarea from 'src/ui/form-ui/Textarea';

const StyledForm = styled.form`
    padding: ${({ theme }) => `${theme.spacing.s} 0`};
`;
const StyledSection = styled.section`
    margin-bottom: ${({ theme }) => theme.spacing.m};
`;
const StyledButtonWrap = styled.div`
    position: relative;
    display: flex;
    gap: ${({ theme }) => theme.spacing.xs};
`;

const QuizCreatePage = () => {
    const theme = useTheme();
    // useLeavePageConfirmation(); // TODO: comment in

    const { isLoading: generateIsLoading, fetchData: generateFetchData } = useFetch(config.url.GENERATE_TEST);

    const [generatedQuiz, setGeneratedQuiz] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const autoGenerateSubmitHandler = async () => {
        generateFetchData({ method: 'GET' }, (data) => {
            console.log(data);
            setGeneratedQuiz(data.data);
            setIsModalOpen(false);
        });
    };

    if (generateIsLoading) {
        return <SpinnerScreen />;
    }

    return (
        <>
            <>
                <Container>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <LocalNav
                            label="Back to Quiz Lists"
                            to={'/quizs'}
                        />

                        <Button
                            onClick={() => setIsModalOpen(true)}
                            variant="contained"
                            color="primaryLight"
                            disabled={generateIsLoading}
                        >
                            <ArrowClockwise />
                            Generate Quiz
                        </Button>
                    </div>

                    <StyledForm>
                        <StyledSection>
                            <Title component="h4">Quiz Image</Title>
                            <DropZone imageSize={{ width: '250px', height: '180px' }} />
                        </StyledSection>

                        <StyledSection>
                            {/* TODO: */}
                            {generatedQuiz.length === 0 ? (
                                <>
                                    <Textarea
                                        label="Question 1:"
                                        id="quiz"
                                        style={{ flex: 1, height: '100%' }}
                                    />
                                    <Textarea
                                        label="Answer 1:"
                                        id="quiz"
                                        style={{ flex: 1, height: '100%' }}
                                    />
                                </>
                            ) : (
                                generatedQuiz.map((item, index) => (
                                    <>
                                        <Textarea
                                            label={`Question ${index + 1}:`}
                                            id={`question_${index + 1}:`}
                                            style={{ flex: 1, height: '100%' }}
                                            value={item.question}
                                        />
                                        <Textarea
                                            label={`Answer ${index + 1}:`}
                                            id={`answer_${index + 1}:`}
                                            style={{ flex: 1, height: '100%' }}
                                            value={item.answer}
                                        />
                                    </>
                                ))
                            )}
                        </StyledSection>

                        <StyledButtonWrap>
                            <Button
                                variant="contained"
                                color="primaryLight"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                Save
                            </Button>
                        </StyledButtonWrap>
                    </StyledForm>
                </Container>
                <Modal
                    isOpen={isModalOpen}
                    closeHandler={() => setIsModalOpen(false)}
                >
                    <div style={{ textAlign: 'center', paddingTop: theme.spacing.s }}>
                        <Title component="h3">Do you want to auto generate a quiz?</Title>
                        <div style={{ display: 'flex', gap: theme.spacing.s, justifyContent: 'center', marginTop: theme.spacing.m }}>
                            <Button
                                onClick={autoGenerateSubmitHandler}
                                variant="contained"
                                color="primaryLight"
                            >
                                Yes, auto generate a quiz
                            </Button>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => setIsModalOpen(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>
            </>
        </>
    );
};

export default QuizCreatePage;
