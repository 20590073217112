import React, { useEffect, useState } from 'react';
import { RadialBarChart, RadialBar, ResponsiveContainer, PolarAngleAxis } from 'recharts';
import { useTheme } from 'styled-components';

const ProgressBar = ({ circleSize, percentage, goalPercentage }) => {
    const theme = useTheme();
    const [data, setData] = useState([{ id: '1', name: 'L1', value: percentage }]);

    useEffect(() => {
        setData([{ id: '1', name: 'L1', value: percentage }]);
    }, [percentage]);

    return (
        <div style={{ width: `${circleSize}px`, height: `${circleSize}px` }}>
            <ResponsiveContainer
                width="100%"
                height="100%"
            >
                <RadialBarChart
                    width={circleSize}
                    height={circleSize}
                    cx={circleSize / 2}
                    cy={circleSize / 2}
                    innerRadius={circleSize / 2}
                    outerRadius={circleSize / 3}
                    barSize={circleSize * 0.1}
                    data={data}
                    startAngle={90}
                    endAngle={-270}
                >
                    <PolarAngleAxis
                        type="number"
                        domain={[0, 100]}
                        angleAxisId={0}
                        tick={false}
                    />
                    <RadialBar
                        background
                        clockWise
                        dataKey="value"
                        cornerRadius={circleSize / 2}
                        fill={data[0].value >= goalPercentage ? theme.colors.successLight : theme.colors.warningLight}
                    />
                    <text
                        x={circleSize / 2}
                        y={circleSize / 1.95}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        className="progress-percentage"
                        style={{
                            fontSize: circleSize / 5,
                            fill: data[0].value >= goalPercentage ? theme.colors.success : theme.colors.warning,
                            fontWeight: theme.fontWeight.xl,
                        }}
                    >
                        {data[0].value}%
                    </text>
                </RadialBarChart>
            </ResponsiveContainer>
        </div>
    );
};
export default ProgressBar;
