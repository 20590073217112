const theme = {
    colors: {
        primary: '#4889FA',
        primaryLight: '#DBF7FF',
        primaryLight2: '#f4fdff',

        secondary: '#FFAA71',
        secondaryLight: '#FFE0C5',

        warning: '#DF545B',
        warningLight: '#FFC0C0',
        warningLight2: '#FFEBEB',

        success: '#80CD72',
        successLight: '#BCEDB9',
        successLight2: '#DCF6DA',

        darkGray: '#4E4E4E',
        gray: '#c0c0c0',
        lightGray: '#f3f3f3',
        black: '#202529',
        white: '#fff',

        // red: '#D72932',
        // pink: '#FD89BF',
        // lightPink: '#FFD8D8',
        // orange: '#FFAA71',
        // lightOrange: '#FFE5CF',
        // blue: '#4889FA',
        // lightBlue: '#D0F5FF',
        // green: '#daf6d9',
        // lightGreen: '#BCEDB9',
        // purple: '#A888FF',
        // lightPurple: '#f8d2fd',
        // lightPurple: '#FDF0FF',
    },
    fontFamilies: {
        primary: "'Raleway', sans-serif",
    },
    fontSize: {
        xs: '12px',
        s: '14px',
        m: '16px',
        l: '20px',
        xl: '25px',
        xxl: '32px',
    },
    fontWeight: {
        m: 300,
        l: 500,
        xl: 700,
    },
    spacing: {
        xxs: '4px',
        xs: '8px',
        s: '16px',
        m: '24px',
        l: '32px',
        xl: '64px',
    },
    breakPoints: {
        xs: 0,
        s: '600px',
        m: '900px',
        l: '1200px',
        xl: '1536px',
    },
    borderRadius: {
        s: '5px',
        m: '16px',
        l: '32px',
    },
    shadow: {
        s: '0 2px 12px 0 #c0c0c0',
        //  m: '',
    },
    avatarSize: {
        xs: '36px',
        s: '60px',
        // s: '50px',
        // m: '',
        // l: '200px',
    },
    imageSize: {
        // xs: '30px',
        // s: '50px',
        m: { width: '200px', height: '150px' },
        l: { width: '300px', height: '220px' },
    },
};

export default theme;
