import React, { useState } from 'react';
import styled from 'styled-components';
import { CloudUpload } from 'react-bootstrap-icons';
import Button from 'src/ui/Button';

const StyledDropZone = styled.div`
    height: ${({ imageSize }) => imageSize.height};
`;

const StyledPreviewWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: flex-end;
    gap: ${({ theme }) => theme.spacing.xs};
`;

const StyledPreviewImage = styled.img`
    width: ${({ imageSize }) => imageSize.width};
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.m};
    object-fit: cover;
`;

const StyledIconWrapper = styled.div`
    position: relative;
    width: ${({ imageSize }) => imageSize.width};
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius.m};
    border: ${({ theme }) => `1px solid ${theme.colors.gray}`};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
`;

const StyledFileInput = styled.input`
    border: 1px solid;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    cursor: pointer;
`;

const StyledErrorMessage = styled.div`
    padding: ${({ theme }) => theme.spacing.xs};
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.colors.warning};
    position: absolute;
    bottom: ${({ theme }) => theme.spacing.s};
`;

const DropZone = ({ file, setFile, imageSize }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const onChangeFileHandler = (event) => {
        setErrorMessage('');
        // TODO: set real number
        if (event.target.files[0].size > 1000000) {
            return setErrorMessage('Please upload less than 1 MB file');
        }
        if (event.target.files) {
            setFile(event.target.files[0]);
        }
    };

    return (
        <StyledDropZone imageSize={imageSize}>
            {file ? (
                <StyledPreviewWrapper>
                    <StyledPreviewImage
                        imageSize={imageSize}
                        src={URL.createObjectURL(file)}
                        alt="upload image preview"
                    />
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={() => setFile('')}
                    >
                        Cancel
                    </Button>
                </StyledPreviewWrapper>
            ) : (
                <StyledIconWrapper imageSize={imageSize}>
                    <CloudUpload size="50" />
                    <div>Select or Drop Image</div>
                    <StyledErrorMessage>{errorMessage}</StyledErrorMessage>
                    <StyledFileInput
                        type="file"
                        onChange={(event) => onChangeFileHandler(event)}
                    />
                </StyledIconWrapper>
            )}
        </StyledDropZone>
    );
};

export default DropZone;
