import { useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useFetch = (url) => {
    const { getAccessTokenSilently } = useAuth0();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    const fetchData = useCallback(
        async (options = {}, successCallback = null, errorCallbackError = null) => {
            setIsLoading(true);
            setIsSuccess(false);
            setErrorMessage(null);

            try {
                let authHeaders = {};
                const accessToken = await getAccessTokenSilently();
                authHeaders = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };

                const optionsWithHeader = { ...options, credentials: 'same-origin', mode: 'same-origin', headers: { ...options.headers, ...authHeaders } };
                const res = await fetch(url, optionsWithHeader);
                if (!res.ok) {
                    throw new Error(res.status);
                }
                const data = await res.json();
                setData(data);

                if (successCallback) {
                    successCallback(data);
                }

                setIsSuccess(true);
            } catch (error) {
                setErrorMessage('Something went wrong. Please try again.');
                setIsSuccess(false);
                if (errorCallbackError) {
                    errorCallbackError(error);
                }
            }

            setIsLoading(false);
        },
        [getAccessTokenSilently, url]
    );

    const initialize = () => {
        setData([]);
        setIsLoading(false);
        setIsSuccess(false);
        setErrorMessage(null);
    };

    return { data, errorMessage, isLoading, isSuccess, fetchData, initialize };
};

export default useFetch;
