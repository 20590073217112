import { css } from 'styled-components';

const scrollBarStyle = css`
    /* Scroll bar styling */
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    ::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.gray};
        border-radius: 3px;
    }
    /* For FireFox styling */
    scrollbar-color: ${({ theme }) => `${theme.colors.gray} transparent`};
`;
export default scrollBarStyle;
