import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
// import useLeavePageConfirmation from 'src/hooks/useLeavePageConfirmation';
import useFetch from 'src/hooks/useFetch';
import ChatFormsSection from 'src/components/attempts/ChatFormsSection';
import ChatScreenSection from 'src/components/attempts/ChatScreenSection';
import Note from 'src/components/attempts/Note';
import LocalNav from 'src/ui/LocalNav';
import Tabs from 'src/ui/Tabs/index';
import TabPane from 'src/ui/Tabs/TabPane';
import { config } from 'src/constants/endpoints';
import { useParams, useNavigate } from 'react-router-dom';
import Accordion from 'src/ui/Accordion/Accordion';
import scrollBarStyle from 'src/styles/scrollBarStyle';
import SuccessScreen from 'src/ui/SuccessScreen';
import useAuthContext from 'src/hooks/useAuthContext';
import { Book, CardChecklist } from 'react-bootstrap-icons';

const StyledScenarioAttemptPage = styled.div`
    display: flex;
    gap: ${({ theme }) => theme.spacing.xs};
`;

const StyledLeft = styled.div`
    display: flex;
    flex-direction: column;
    /* 32px = 14px + padding  + 2px of LocalNav. Use useRef() if needed */
    height: ${({ theme }) => `calc(100vh - (${theme.spacing.m})*2 - 32px)`};
    width: 50%;
`;
const StyledRight = styled.div`
    width: 50%;
    border-left: ${({ theme }) => `1px solid ${theme.colors.lightGray}`};
    padding-left: ${({ theme }) => theme.spacing.s};
`;
const StyledReference = styled.div`
    ${scrollBarStyle}
    //32px = LocalNav height, 35px = tab title. Use useRef() if needed
    height:${({ theme }) => `calc(100vh - (${theme.spacing.m})*2 - 32px - 43px)`};
    overflow-y: scroll;
`;

const ScenarioAttemptPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { courseId, scenarioId } = useParams();
    const { errorMessage: chatErrorMessage, isLoading: chatIsLoading, fetchData: chatFetchData } = useFetch(config.url.CHAT);
    const {
        //  errorMessage: attemptErrorMessage, // TODO: implement this
        // isLoading: attemptIsLoading, // TODO: implement this
        isSuccess: attemptIsSuccess,
        fetchData: attemptFetchData,
    } = useFetch(config.url.ATTEMPTS);

    const {
        fetchData: feedbackFetchData,
        errorMessage: feedbackErrorMessage,
        isSuccess: feedbackIsSuccess,
        initialize: feedbackInitialize,
        isLoading: feedbackIsLoading,
    } = useFetch(config.url.FEEDBACK);

    const { fetchData: scenarioFetchData } = useFetch(`${config.url.SCENARIOS}${scenarioId}`);

    const { currentUser } = useAuthContext();

    const [messages, setMessages] = useState([]);
    const [note, setNote] = useState({});
    const [chatInputVal, setChatInputVal] = useState('');
    const [physicalExamInputVal, setPhysicalExamInputVal] = useState('');
    const [responseData, setResponseData] = useState(''); // Set id of the attempt to build url of the attempt detail page

    // useLeavePageConfirmation(); // TODO: Implement to disable functionality once success screen is visible

    // Checking courseId and/or scenarioId is valid by making GET api call
    useEffect(() => {
        // scenarioId
        scenarioFetchData({}, null, (error) => {
            if (error.message === '404') {
                navigate('/not-found');
            }
        });
        // Don't want to add navigate()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scenarioFetchData]);

    const messageSubmitHandler = async (event, category) => {
        event.preventDefault();
        // Displaying feedback button
        feedbackInitialize(); // To initialize feedback error message
        // TODO: refactor this
        let reqBody = '';
        if (category === 'chat') {
            // set users message into messages state
            setMessages((prev) => {
                return [...prev, { user: 'human', text: chatInputVal, mode: 'chat' }];
            });
            reqBody = JSON.stringify({ scenario_id: scenarioId, message: chatInputVal, history: messages, mode: 'chat' }); // mode: 'narrator'
        }

        if (category === 'physical exam') {
            // set users message into messages state
            setMessages((prev) => {
                return [...prev, { user: 'human', text: physicalExamInputVal, mode: 'narrator' }];
            });
            reqBody = JSON.stringify({ scenario_id: scenarioId, message: physicalExamInputVal, history: messages, mode: 'narrator' }); // mode: 'narrator'
        }

        chatFetchData(
            {
                method: 'POST',
                body: reqBody,
            },
            (data) => {
                const clientRes = {
                    user: 'bot',
                    text: data.text,
                    mode: data.mode,
                };
                // set bot message into messages state
                setMessages((prev) => {
                    return [...prev, clientRes];
                });
            }
        );

        setChatInputVal('');
        setPhysicalExamInputVal('');
    };

    const noteSubmitHandler = async (draft) => {
        const reqBody = {
            scenario_id: scenarioId,
            course_id: courseId,
            note,
            chat_history: {
                history: messages,
            },
        };

        attemptFetchData(
            {
                method: 'POST',
                body: JSON.stringify(reqBody),
            },
            (response) => {
                setResponseData(response);
            }
        );

        // TODO: add draft post
        // console.log('Is draft', draft);
    };

    const feedbackSubmitHandler = () => {
        const body = {
            chat_history: messages,
            feedback: 0, // down -> 0
        };

        feedbackFetchData({
            method: 'POST',
            body: JSON.stringify(body),
        });
    };

    if (attemptIsSuccess) {
        return (
            <>
                <LocalNav
                    to={`/courses/${courseId}/scenarios/${scenarioId}`}
                    label="Back to Scenario"
                />
                <SuccessScreen
                    title="Success!"
                    message="Your attempt is submitted successfully!"
                    url={`/attempts/${responseData.id}`}
                    label="View My Attempt"
                />
            </>
        );
    }

    return (
        <>
            <LocalNav
                to={`/courses/${courseId}/scenarios/${scenarioId}`}
                label="Back to Scenario"
            />
            <StyledScenarioAttemptPage>
                <StyledLeft>
                    <ChatScreenSection
                        messages={messages}
                        isLoading={chatIsLoading}
                        errorMessage={chatErrorMessage}
                        feedbackIsSuccess={feedbackIsSuccess}
                        feedbackErrorMessage={feedbackErrorMessage}
                        feedbackSubmitHandler={feedbackSubmitHandler}
                        feedbackIsLoading={feedbackIsLoading}
                    />

                    <ChatFormsSection
                        chatInputVal={chatInputVal}
                        setChatInputVal={setChatInputVal}
                        physicalExamInputVal={physicalExamInputVal}
                        setPhysicalExamInputVal={setPhysicalExamInputVal}
                        submitHandler={messageSubmitHandler}
                        isLoading={chatIsLoading}
                    />
                </StyledLeft>
                <StyledRight>
                    <Tabs
                        preSelectedTabIndex={0}
                        isFullWidth={true}
                    >
                        <TabPane
                            title={
                                <>
                                    <CardChecklist />
                                    Note
                                </>
                            }
                        >
                            <Note
                                style={{ height: `calc(100vh - (${theme.spacing.m})*2 - 32px - 43px)` }} //32px = LocalNav height, 35px = tab title. Use useRef() if needed
                                note={note}
                                setNote={setNote}
                                submitHandler={noteSubmitHandler}
                                showButtons={currentUser.isStudent}
                            />
                        </TabPane>
                        <TabPane
                            title={
                                <>
                                    <Book />
                                    Reference
                                </>
                            }
                        >
                            <StyledReference>
                                <Accordion /> {/* TODO pass contents to Accordion*/}
                                {/* TODO: Replace with real content */}
                                <div style={{ lineBreak: 'anywhere' }}>
                                    Example taken from: https://cfsecure.nova.edu/peprx/documents/evaluation_ippe_commee_ii_medicationprofile.pdf
                                </div>
                            </StyledReference>
                        </TabPane>
                    </Tabs>
                </StyledRight>
            </StyledScenarioAttemptPage>
        </>
    );
};

export default ScenarioAttemptPage;
