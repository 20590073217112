import React from 'react';
import styled, { useTheme } from 'styled-components';
import scrollBarStyle from 'src/styles/scrollBarStyle';
import Input from 'src/ui/form-ui/Input';
import Title from 'src/ui/Title';
import Textarea from 'src/ui/form-ui/Textarea';
import CheckBox from './CheckBox';
import { Label } from './Label';

const StyledFormInputs = styled.div`
    ${scrollBarStyle}
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing.xs};
    overflow-y: scroll;
`;

const StyledTitle = styled(Title)`
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.xs};
    margin-bottom: 0;
`;
const StyledTextarea = styled(Textarea)`
    width: 100%;
`;

const FormInputs = ({ className, state, setState, elements, readOnly }) => {
    const theme = useTheme();

    return (
        <StyledFormInputs className={className}>
            {elements.map((element) => {
                switch (element.component) {
                    case 'h4':
                        return (
                            <StyledTitle
                                key={element.title}
                                component="h4"
                            >
                                {element.title}
                            </StyledTitle>
                        );
                    case 'input':
                        return (
                            <Input
                                key={element.id}
                                type={element.inputType ? element.inputType : 'text'}
                                id={element.id}
                                label={element.label}
                                value={state[element.property] ?? ''}
                                onChange={(event) => setState({ ...state, [element.property]: event.target.value })}
                                readOnly={readOnly}
                                isRequired={element.isRequired}
                            />
                        );
                    case 'textarea':
                        return (
                            <StyledTextarea
                                key={element.id}
                                id={element.id}
                                label={element.label}
                                value={state[element.property] ?? ''}
                                onChange={(event) => setState({ ...state, [element.property]: event.target.value })}
                                rows={element.rows}
                                readOnly={readOnly}
                                isRequired={element.isRequired}
                            />
                        );

                    case 'checkboxGroup':
                        return (
                            <div key={element.id}>
                                <Label style={{ marginBottom: theme.spacing.xs, display: 'block' }}>{element.label}</Label>
                                <div style={{ paddingLeft: theme.spacing.xs }}>
                                    {element.checkbox.map((item) => {
                                        const property = element.property;

                                        return (
                                            <CheckBox
                                                key={item.id}
                                                id={item.id}
                                                value={item.value}
                                                label={item.label}
                                                isChecked={state[property].includes(item.value.toString())}
                                                onChangeHandler={(event) => {
                                                    const eventTargetValue = event.target.value;

                                                    return setState((prev) => {
                                                        let newCheckedArray = [];
                                                        const isChecked = prev[property].includes(eventTargetValue.toString());

                                                        if (isChecked) {
                                                            // Removing if it's checked
                                                            newCheckedArray = prev[property].filter((item) => item !== eventTargetValue);
                                                        } else {
                                                            // Adding if it's not checked
                                                            newCheckedArray = [...prev[property], eventTargetValue];
                                                        }

                                                        return { ...state, [property]: newCheckedArray };
                                                    });
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        );

                    default:
                        return null;
                }
            })}
        </StyledFormInputs>
    );
};

export default FormInputs;
